import AbstractComponent from '../abstract-component.js';


const getFilesMarkup = (files) => {
  return files.map((file) => {
    return (
      `<li class="modal__progressWrap" data-order-id="${file.id}">
        <p>Файл: ${file.name}</p>
        <progress class="form-registration__progress form-registration__progress--video"
          style="width: 100%;"
          value="0"
          max="${file.size}"
          data-progress="0%"
        ></progress>
      </li>`
    );
  }).join(`\n`);
};

const createLoadProgressTemplate = (files, isAbort) => {
  const filesMarkup = getFilesMarkup(files);

  const text = (files.length === 1) ? `файла` : `${files.length} файлов`;

  return (
    `<div class="modal__loadFileWrap">
      <div class="modal__loadFile">
        <p class="modal__text"><b>Загрузка ${text} на сервер</b></p>

        <ul class="list">
          ${filesMarkup}
        </ul>
            
        <div class="modal__spinner">
          <p class="modal__text">Пожалуйста, подождите...</p>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        ${isAbort ? `<button class="modal__button modal__button--asLink" type="button">Отменить загрузку</button>` : ``}
      </div>
    </div>`
  );
};

export default class LoadProgressComponent extends AbstractComponent {
  constructor(files, isAbort) {
    super();

    this._files = files;
    this._isAbort = isAbort;
  }

  getTemplate() {
    return createLoadProgressTemplate(this._files, this._isAbort);
  }

  changeProgress(id, value, progress) {
    const fileItem = this.getElement().querySelector(`.modal__progressWrap[data-order-id="${id}"]`);
    const progressElement = fileItem.querySelector(`progress`);
    progressElement.value = value;
    progressElement.dataset.progress = progress;
  }

  setAbortButtonClickHandler(handler) {
    if (!handler || !this.getElement().querySelector(`.modal__button`)) {
      return;
    }

    this.getElement().querySelector(`.modal__button`).addEventListener(`click`, handler);
  }
}
