import API from './api/index.js';
import FooterController from './controllers/footer.js';
import LoginIndexPageController from './controllers/login-index-page';
import {reloadPage} from './utils/common.js';


const api = new API();

const loginContainerElement = document.querySelector(`.main__login`);
const loginController = new LoginIndexPageController({
  api,
  // onAuthorization: reloadPage,
});

loginController.render(loginContainerElement);
loginController.setAutnHandler(() => reloadPage());


const footerElement = document.querySelector(`.footer`);
const footerController = new FooterController({
  container: footerElement,
});
footerController.render();

// 07/08/2022 отключено, т.к.добавлен резервный домен
// const nameSiteElement = document.querySelector(`.main__title--logo`);
// const namesite = window.location.hostname;
// nameSiteElement.textContent = namesite; /* тут можно добавить punycode */
