import {NTR} from "../../name-to-request";

export const PeopleParameter = {
  POSITION: {[NTR.PEOPLE_DIRECTOR]: NTR.EMPTY},
  PHONE: {[NTR.PEOPLE_PHONE_NUMBER]: NTR.EMPTY},
  EMAIL: {[NTR.PEOPLE_EMAIL]: NTR.EMPTY},
  NAME: {[NTR.PEOPLE_NAME]: NTR.EMPTY},
  DOB: {[NTR.PEOPLE_DOB]: NTR.EMPTY},
  AUTH: {[NTR.PEOPLE_PASS]: NTR.EMPTY},
  ALIAS: {[NTR.PEOPLE_ALIAS]: NTR.EMPTY},

  CARD_CONTEST: {[NTR.PEOPLE_CARD]: NTR.CONTEST},
  CARD_ALL: {[NTR.PEOPLE_CARD]: NTR.ALL},
};

export const ALL_PEOPLE_PARAMETERS = [
  PeopleParameter.POSITION,
  PeopleParameter.PHONE,
  PeopleParameter.EMAIL,
  PeopleParameter.NAME,
  PeopleParameter.DOB,
  PeopleParameter.AUTH,
  PeopleParameter.CARD_CONTEST,
];
