import Popup from "../static/popup/popup";

export class NoAccessError extends Error {
  constructor({message = ``, id = ``, parameter = {}}) {
    super(message);

    this.name = `NoAccessError`;
    this.id = id;
    this.textError = `<b>У вас нет прав на совершение этой операции.</b><br>
    Пожалуйста свяжитесь с организатором или технической поддержкой.`;
    this.parameter = parameter;
  }

  show() {
    Popup.showError({
      text:
        `${this.textError} <br>
        ${this.id ? `Код ошибки: ${this.id}.` : ``} `,
    });
  }
}
