import {ScriptError} from "../../error/script";
import {isEmptyArray} from "../../utils/common";

export const checkRequest = ({checkedAvailableParameters = {}, checkedRequiredParameter = {}}) => {
  // проверка на наличие данных
  const keys = Object.keys(checkedRequiredParameter);

  keys.forEach((key) => {
    if (typeof checkedAvailableParameters[key] === `undefined`) {
      throw new ScriptError({
        message: `${key} is undefined`,
      });
      // throw error;
    }
  });

  // проверка обязательных параметров (пока на пустоту, потом можно донастроить)
  const keys2 = Object.keys(checkedRequiredParameter);

  keys2.forEach((key) => {
    const checkedParameter = checkedRequiredParameter[key];

    if (Array.isArray(checkedParameter)) {
      if (isEmptyArray(checkedParameter)) {
        throw new ScriptError({
          message: `${key} is empty`,
        });
        // throw error;
      }
    } else if (typeof checkedParameter !== `boolean` && !checkedParameter) {
      throw new ScriptError({
        message: `${key} is empty`,
      });
      // throw error;
    }
  });
};


// module.exports = checkRequest;
