import API from "../api";
import ErrorData from "../data/error/error";
import Popup from "../static/popup/popup";

export class ScriptError extends Error {
  constructor({message, isShowError = true}) {
    super(message);

    this.name = `ScriptError`;
    this.isShowError = isShowError;
  }

  show(errorId = ``) {
    Popup.showError({text: `Произошла ошибка. Код ошибки: ${errorId}`});
  }

  send() {
    return new ErrorData({api: new API()}).request({
      nameError: this.name,
      message: this.message,
      stack: this.stack,
      request: this._request,
      response: this._response
    })
    .then((errorId) => {
      if (this.isShowError) {
        this.show(errorId);
      }

      return errorId;
    });
  }
}
