import {NTR} from "../../name-to-request";

export default class RefereeParsing {
  static criteria(data) {
    const refereeCriteria = data.referee_criteria;

    if (!refereeCriteria || refereeCriteria === NTR.NO_ACCESS) {
      return [];
    }

    return refereeCriteria.split(`,`);
  }
}
