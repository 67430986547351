import Popup from "../static/popup/popup";

export class BlockedError extends Error {
  constructor({message = ``, id = ``, parameter = {}}) {
    super(message);

    this.name = `BlockedError`;
    this.id = id;
    this.textError = `Для редактирования данных выступления, разблокируйте его.`;
    this.parameter = parameter;
  }

  show() {
    Popup.showError({
      text:
        `${this.textError} <br>
        ${this.id ? `Код ошибки: ${this.id}.` : ``} `,
    });
  }
}
