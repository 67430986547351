import {NTR} from "../../name-to-request";

export default class NominationParsing {
  static method(data) {
    const method = data.nomination_rating_method;
    return (!method || method === `null` || method === NTR.NO_ACCESS) ? `` : method;
  }

  static documentMaxPeople(data) {
    const maxCount = data.document_max_people;

    if (!maxCount || maxCount === null || maxCount === `null`) {
      return Infinity;
    }

    return +maxCount;
  }
}
