import {NTR} from '../../name-to-request.js';
import Popup from '../../static/popup/popup.js';
import {getCookie} from '../../utils/common.js';

export default class SetDataError {
  static info(api, error, request = ``) {
    // eslint-disable-next-line no-console
    console.log(error);

    const errorText = (request !== ``) ? `${error}, request: ${request}` : error;
    const contestId = getCookie(`contest_id`) || null;

    return api.setParameter({
      [NTR.SET_ERROR_INFO]: {
        [NTR.ERROR_INFO]: `${errorText}, contestId: ${contestId}`,
      }
    })
    .then((response) => Popup.showError({text: `Произошла ошибка. Код ошибки: ${response.id}`}));
  }

  static infoDebug(api, error, request = ``) {
    // eslint-disable-next-line no-console
    console.log(error);

    const errorText = (request !== ``) ? `${error}, request: ${request}` : error;
    const contestId = getCookie(`contest_id`) || null;

    return api.setParameter({
      [NTR.SET_ERROR_INFO]: {
        [NTR.ERROR_INFO]: `${errorText}, contestId: ${contestId}`,
      }
    })
    .then((response) => Popup.showError({text: `Произошла ошибка: ${error}. Код ошибки: ${response.id}`}));
  }

  static showError({api, error, message = `Произошла ошибка`}) {
    // eslint-disable-next-line no-console
    console.log(error);

    const contestId = getCookie(`contest_id`) || null;

    return api.setParameter({
      [NTR.SET_ERROR_INFO]: {
        [NTR.ERROR_INFO]: `${error}, contestId: ${contestId}`,
      }
    })
    .then((response) => Popup.showError({
      text:
        `${message} <br>
        Код ошибки: ${response.id}`,
    }));
  }

  static request(api, nameError, error, stack, request, errorResponse) {
    const contestId = getCookie(`contest_id`) || null;

    return api.setParameter({
      [NTR.SET_ERROR_INFO]: {
        [NTR.ERROR_INFO]: {
          [nameError]: error,
          [NTR.STACK]: stack,
          [NTR.REQUEST]: request,
          [NTR.RESPONSE]: errorResponse,
          [NTR.CONTEST_ID]: contestId,
        }
      }
    });
  }

  static test({api, error, request = ``}) {
    const errorText = (request !== ``) ? `${error}, request: ${request}` : error;
    const contestId = getCookie(`contest_id`) || null;
    const peopleId = getCookie(`people_id`) || null;

    api.setParameter({
      [NTR.SET_ERROR_INFO]: {
        [NTR.ERROR_INFO]: `${errorText}, contestId: ${contestId}, peopleId: ${peopleId}`,
      }
    });
  }

  static debug({api, errorText = ``}) {
    const contestId = getCookie(`contest_id`) || null;
    const peopleId = getCookie(`people_id`) || null;

    api.setParameter({
      [NTR.SET_ERROR_INFO]: {
        [NTR.ERROR_INFO]: `${errorText}, contestId: ${contestId}, peopleId: ${peopleId}`,
      }
    });
  }
}
