export const getCursorPosition = (element) => {
  let selection = document.getSelection();
  let range = new Range();
  range.setStart(element, 0);
  range.setEnd(selection.anchorNode, selection.anchorOffset);
  return range.toString().length;
};

export const setCursorPosition = (element, position = 0) => {
  if (!position) {
    element.focus();
    return;
  }
  let child = element.firstChild;
  while (position >= 0) {
    let length = child.textContent.length;
    if (position > length) {
      position -= length;
      child = child.nextSibling;
    } else {
      if (child.nodeType === 3) {
        document.getSelection().collapse(child, position);
        return;
      }
      child = child.firstChild;
    }
  }
};

export const getCursorPositionNode = (element, position) => {
  let child = element.firstChild;
  while (position >= 0) {
    let length = child.textContent.length;
    if (position > length) {
      position -= length;
      child = child.nextSibling;
    } else {
      if (child.nodeType === 3) {
        // document.getSelection().collapse(child, position);
        return child;
      }
      child = child.firstChild;
    }
  }

  return element.firstChild; // ?
};


