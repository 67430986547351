import {getChangedQuotText} from '../../../utils/common.js';
import AbstractInputComponent from '../abstract-input.js';
import trashSvg from './../../../../svg/trash.svg';

// ${isDropDown ?
//   `<button class="dropDownList__btnOpen dropDownList__btnOpen--oneArrow" data-status="close" type="button" name="button">
//     <span class="visuallyhidden">Выпадающий список</span>
//   </button>` : ``}


const createTextInputTemplate = (label, value, placeholder, isRequired, isReadOnly, isDropDown, isDeleteButton, isTrashButton, isOpenDropDownItems, isDisabled, prompt, extraClassName, style) => {
  const textValue = getChangedQuotText(value);

  return (
    `<div class="dropDownList__wrap ${extraClassName}" 
      data-new="true" 
      data-required="${isRequired}" 
      data-validate="base" 
      data-btn-status="${isDropDown ? `show` : `hide`}"
      data-list="${isOpenDropDownItems ? `show` : `hide`}" 
      data-option="hide" 
      data-btn="out" 
      data-disabled="${isDisabled}"
      data-prompt="${prompt !== ``}"
      ${style ? `style="${style}"` : ``} 
    >

    <button class="dropDownList__btnOpen dropDownList__btnOpen--oneArrow" data-status="close" type="button" name="button">
      <span class="visuallyhidden">Выпадающий список</span>
    </button>

      ${label !== `` ? `<label class="dropDownList__label">${label}</label>` : ``}

      <input class="dropDownList__input" type="text" name="" 
        value="${textValue}" 
        ${isReadOnly ? `readonly` : ``}
        placeholder="${placeholder}">

      ${isDeleteButton ?
      `<button class="master__btn master__btn--delete" type="button" title="Удалить">
          <span class="visuallyhidden">Удалить</span>
        </button>` : ``}

      ${isTrashButton ?
      `<button class="master__btn master__btn--deleteSvg" type="button" title="Очистить">
        <svg aria-hidden="true" width="25" height="30">
          <use class="" href="${trashSvg}"/>
        </svg>
      </button>` : ``}

      ${prompt !== `` ?
      `<button class="dropDownList__btnPrompt" type="button" name="button" tabindex="-1"><span class="visuallyhidden">Открыть дополнительную информацию</span> </button>
        <p class="dropDownList__prompt">${prompt}</p>`
      : ``}
    </div>`
  );
};


export default class TextInputComponent extends AbstractInputComponent {
  getTemplate() {
    return createTextInputTemplate(this._label, this._value, this._placeholder, this._isRequired, this._isReadOnly, this.isDropDown, this._isDeleteButton, this._isTrashButton, this._isOpenDropDownItems, this._isDisabled, this._prompt, this._extraClassName, this._style);
  }

  _validate() {
    if (this.value !== `` && this._customValidate(this.value)) {
      this.isValid = true;
      return;
    }

    this.isValid = false;
  }

  _onInputHandler() {
    this._searchDropDownItems();
  }
}
