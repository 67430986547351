import RequestController from "../../static/request/request";
import LoginRequest from "./request";

export default class LoginData {
  constructor({api}) {
    this._api = api;
  }

  login(login, password, onResolveHandler, onRequestErrorHandler) {
    new RequestController({
      api: this._api,
      getRequest: LoginRequest.logon,
      parameter: {login, password},
      checkRequiredParameter: {login, password},
      requiredResponseParameters: [],
      onResolveHandler: () => onResolveHandler(),
      onRejectHandler: () => {},
      onRequestErrorHandler: (error) => onRequestErrorHandler(error),
    }).send();
  }

  logout(onResolveHandler) {
    new RequestController({
      api: this._api,
      getRequest: LoginRequest.logout,
      parameter: {},
      checkRequiredParameter: {},
      requiredResponseParameters: [],
      onResolveHandler: () => onResolveHandler(),
      onRejectHandler: () => {},
      onRequestErrorHandler: (error) => error.showCustom(`Не удалось выйти из системы.`),
    }).send();
  }
}
