export const getDataRequest = (parameters) => {
  const data = {};

  parameters.forEach((parameter) => {
    const key = Object.keys(parameter)[0];
    data[key] = parameter[key];
  });

  return data;
};
