export const definePhonogramStatus = (statusId) => {
  switch (statusId) {
    case null:
      return {name: `Не загружена`, id: `none`, info: `base`};
    case ``:
      return {name: `Не загружена`, id: `none`, info: `base`};
    case `-1`:
      return {name: `Удалена`, id: `-1`, info: `base`};
    case `0`:
      return {name: `Не проверена`, id: `0`, info: `check`};
    case `1`:
      return {name: `Все не проверены`, id: `1`, info: `check`};
    case `2`:
      return {name: `Не все проверены`, id: `2`, info: `check`};
    case `5`:
      return {name: `Принята`, id: `5`, info: `ok`};
    case `6`:
      return {name: `Все одобрены`, id: `6`, info: `ok`};
    case `10`:
      return {name: `Отклонена`, id: `10`, info: `error`};
    case `11`:
      return {name: `Отклонена: низкое качество звука`, id: `11`, info: `error`};
    case `20`:
      return {name: `Все отклонены`, id: `20`, info: `error`};
    case `21`:
      return {name: `Не все приняты`, id: `21`, info: `error`};
    default:
      return {name: `Не загружена`, id: `none`, info: `base`};
  }
};
