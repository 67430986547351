import {SortType} from "../const/sort";

export const getSortOrder = (sortType, isSortUp) => `${sortType}|${isSortUp ? `u` : `d`}`;

export const parsingOrder = (data) => {
  if (!data) {
    return ``;
  }

  return data.replace(/turn_id/g, SortType.TURN_ID);
};
