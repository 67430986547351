import Popup from "../static/popup/popup";

export class FetchError extends Error {
  constructor({message}) {
    super(message);

    this.name = `FetchError`;
    this.type = `connection`;
  }

  show() {
    Popup.showNotification({
      text: `Потеря пакетов при передаче серверу, проверьте соединение!`,
      color: `#f1cc4c`,
    });
  }
}
