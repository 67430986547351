import { FormatDocument, OrientationDocument } from "../const";

export const DocumentsType = {
  GOOGLE: `google`,
  POPUP: `popup`,
  NONE: `none`,
  FRONT: `front`,
};


export const DOCUMENT_DEFAULT = {
  documentId: ``,
  title: ``,
  order: ``,
  isBase: false,
  type: DocumentsType.NONE,
};

export const DOCUMENT_SETTING_DEFAULT = {
  documentId: `0`,
  title: `Новый документ`,
  content: ``,
  format: FormatDocument.A4,
  orientation: OrientationDocument.PORTRAIT,
  margin: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  isAuth: true,
  isCheckPayment: true,
};
