import {NTR} from '../../name-to-request.js';
import {getDataRequest} from '../../utils/request.js';

export const ContestParameterOld = {
  ADDRESS: {[NTR.ADDRESS]: NTR.EMPTY},
  AFFIRMATION_DEADLINE: {[NTR.DEADLINE_AFFIRMATION]: NTR.EMPTY},
  AGE_CATEGORY: {[NTR.AGE_CATEGORY_TITLE]: NTR.EMPTY},

  CITIES: {[NTR.PERFORMANCE_CITY]: NTR.EMPTY},
  COMPERE: {[NTR.COMPERE]: NTR.EMPTY},
  CRITERIA: {[NTR.CRITERIA_TITLE]: NTR.EMPTY},
  CRITERIA_MAX_POINTS: {[NTR.CRITERIA_MAX_POINTS]: NTR.EMPTY},
  CRITERIA_DESCRIPTION: {[NTR.CRITERIA_NOTE]: NTR.EMPTY},
  CRITERIA_NOMINATION: {[NTR.CRITERIA_NOMINATION]: NTR.EMPTY},

  DATE: {[NTR.DATE]: NTR.EMPTY},
  DIRECTOR: {[NTR.DIRECTOR]: NTR.EMPTY},
  DOCUMENT: {[NTR.CONTEST_DOCUMENT]: NTR.EMPTY},

  LANDMARK: {[NTR.LANDMARK_TITLE]: NTR.EMPTY},
  LANDMARK_START: {[NTR.LANDMARK_START]: NTR.EMPTY},

  NOMINATION: {[NTR.NOMINATION_TITLE]: NTR.EMPTY},

  PAYMENT_DEADLINE: {[NTR.DEADLINE_PAYMENT]: NTR.EMPTY},
  PAYMENT_SYSTEM: {[NTR.PAYMENT_SYSTEM]: NTR.EMPTY},
  PERFORMANCE_COUNT: {[NTR.PERFORMANCE_COUNT]: NTR.EMPTY},
  PERFORMANCE_DURATION_ALL: {[NTR.PERFORMANCE_DURATION_SUM]: NTR.EMPTY},
  PERFORMANCE_TIME_AVAIL: {[NTR.PERFORMANCE_TIME_AVAIL]: NTR.EMPTY},
  PLAYER: {[NTR.PLAYER]: NTR.EMPTY},
  PRICE_PER_PERFORMANCE: {[NTR.CONTEST_SERVICE_PRICE]: NTR.EMPTY},
  PRIZE: {[NTR.PRIZE_OPTION]: NTR.EMPTY},

  REFEREE: {[NTR.REFEREE]: NTR.EMPTY},
  RULES_LINK: {[NTR.RULES_LINK]: NTR.EMPTY},

  SERVER_MAIL: {[NTR.SMTP]: NTR.EMPTY},
  SOUNDMAN: {[NTR.SOUNDMAN]: NTR.EMPTY},

  TARIFF: {[NTR.CONTEST_TARIFF]: NTR.EMPTY},
  TEAM_CITY: {[NTR.TEAM_CITY]: NTR.EMPTY},
  TEAM_TITLE: {[NTR.TEAM_TITLE]: NTR.EMPTY},
  TERMINAL: {[NTR.TERMINAL]: NTR.EMPTY},
  TIME_ZONE: {[NTR.TIME_ZONE]: NTR.EMPTY},

  TITLE: {[NTR.CONTEST_TITLE]: NTR.NULL},
  TITLE_SHORT: {[NTR.CONTEST_TITLE]: NTR.ONE},
  TITLE_LONG: {[NTR.CONTEST_TITLE]: NTR.TWO},

  QUALIFICATION: {[NTR.QUALIFICATION_TITLE]: NTR.EMPTY},
};

const ALL_CONTEST_PARAMETERS = [
  ContestParameterOld.TITLE,
  ContestParameterOld.DATE,
  ContestParameterOld.TIME_ZONE,
  ContestParameterOld.ADDRESS,
  ContestParameterOld.RULES_LINK,
  ContestParameterOld.PERFORMANCE_COUNT,
  ContestParameterOld.PERFORMANCE_DURATION_ALL,
  ContestParameterOld.PERFORMANCE_TIME_AVAIL,

  ContestParameterOld.NOMINATION,
  ContestParameterOld.AGE_CATEGORY,
  ContestParameterOld.QUALIFICATION,
  ContestParameterOld.PRIZE,
  ContestParameterOld.DOCUMENT,
  ContestParameterOld.PLAYER,
  ContestParameterOld.DIRECTOR,
  ContestParameterOld.REFEREE,
  ContestParameterOld.CITIES,
  ContestParameterOld.LANDMARK,
  ContestParameterOld.LANDMARK_START,
  ContestParameterOld.CRITERIA,
  ContestParameterOld.CRITERIA_NOMINATION,
  ContestParameterOld.CRITERIA_DESCRIPTION,
  ContestParameterOld.CRITERIA_MAX_POINTS,
  ContestParameterOld.SERVER_MAIL,
  ContestParameterOld.TEAM_TITLE,
  ContestParameterOld.TEAM_CITY,
  ContestParameterOld.TARIFF,
  ContestParameterOld.PRICE_PER_PERFORMANCE,
  ContestParameterOld.PAYMENT_SYSTEM,

  ContestParameterOld.AFFIRMATION_DEADLINE,
  ContestParameterOld.PAYMENT_DEADLINE,
  ContestParameterOld.TERMINAL,
  ContestParameterOld.SOUNDMAN,
  ContestParameterOld.COMPERE,
];

export const REGISTRATION_CONTEST_PARAMETERS = [
  ContestParameterOld.TITLE,
  ContestParameterOld.DATE,
  ContestParameterOld.TIME_ZONE,
  ContestParameterOld.ADDRESS,
  ContestParameterOld.RULES_LINK,

  ContestParameterOld.NOMINATION,
  ContestParameterOld.AGE_CATEGORY,
  ContestParameterOld.QUALIFICATION,
  ContestParameterOld.TARIFF,
  ContestParameterOld.PRICE_PER_PERFORMANCE,
  ContestParameterOld.PAYMENT_SYSTEM,

  ContestParameterOld.AFFIRMATION_DEADLINE,
  ContestParameterOld.PAYMENT_DEADLINE,
];

export const CONTESTADM_CONTEST_PARAMETERS = [
  ContestParameterOld.TITLE_SHORT,
  // ContestParameter.PERFORMANCE_COUNT,
  // ContestParameter.RULES_LINK,

  ContestParameterOld.NOMINATION,
  ContestParameterOld.AGE_CATEGORY, // для фильтра
  ContestParameterOld.QUALIFICATION,
  ContestParameterOld.PRIZE,
  // ContestParameter.DOCUMENT,
  // ContestParameter.PLAYER,
  // ContestParameter.DIRECTOR,
  // ContestParameter.REFEREE,
  ContestParameterOld.CITIES,
  // ContestParameter.TEAM_TITLE,
  // ContestParameter.TEAM_CITY,

  // ContestParameter.TARIFF,
  // ContestParameter.PRICE_PER_PERFORMANCE,
  // ContestParameter.PAYMENT_SYSTEM,

  // ContestParameter.TERMINAL,
];


export const getDataContest = (contestId, size, filter, parameters = ALL_CONTEST_PARAMETERS) => {
  const data = {
    [NTR.GET_DATA]: {
      [NTR.CONTEST_ID]: contestId,
      [NTR.SIZE]: size,
      [NTR.FILTER]: filter,
      [NTR.DATA]: getDataRequest(parameters),
    }
  };

  return JSON.stringify(data);
};
