import AbstractComponent from '../abstract-component.js';

const createLiTemplate = (className, text) => {
  return (
    `<li class="${className}">${text}</li>`
  );
};

export default class LiComponent extends AbstractComponent {
  constructor(className = ``, text = ``) {
    super();

    this._className = className;
    this._text = text;
  }

  getTemplate() {
    return createLiTemplate(this._className, this._text);
  }
}
