import {remove, render, RenderPosition} from "../utils/render";
import NotificationComponent from "../component/notification/notification";

const NOTIFICATION_DELAY = 3000;

export default class NotificationController {
  constructor({text = ``, delay = NOTIFICATION_DELAY, color = ``, isFade = true, isShowCancelButton = false, onCancelButtonClickHandler = () => {}}) {
    this._container = document.body;
    this._text = text;
    this._delay = delay;
    this._color = color;
    this._isFade = isFade;
    this._isShowCancelButton = isShowCancelButton;
    this._onCancelButtonClickHandler = onCancelButtonClickHandler;

    this._notification = null;
    this._timerId = null;

    this._onTimeoutHandler = this._onTimeoutHandler.bind(this);
    this._onRemoveHandler = this._onRemoveHandler.bind(this);
  }

  render() {
    this._notification = new NotificationComponent({text: this._text});
    if (this._color) {
      this._notification.getElement().style.backgroundColor = this._color;
    }
    render(this._container, this._notification.getElement(), RenderPosition.BEFOREEND);

    if (this._isFade) {
      this._timerId = setTimeout(this._onTimeoutHandler, this._delay);
    }
  }

  remove() {
    this._onRemoveHandler();
  }

  _onRemoveHandler() {
    remove(this._notification);

    clearTimeout(this._timerId);
    this._timerId = null;
  }

  _onTimeoutHandler() {
    this._notification.getElement().classList.add(`fadeOut`);

    setTimeout(this._onRemoveHandler, 500);
  }
}
