import Popup from "../static/popup/popup";

export class WarningError extends Error {
  constructor({message = ``, id = ``, textError = ``, parameter = {}}) {
    super(message);

    this.name = `WarningError`;
    this.id = id;
    this.textError = textError;
    this.parameter = parameter;
  }

  show() {
    const textError = !this.textError ? `Предупреждение.` : this.textError;

    Popup.showWarning({
      text:
        `${textError}`,
    });
  }

  showCustom(textError) {
    if (textError === null) {
      return;
    }

    Popup.showWarning({
      text:
        `${textError}`,
    });
  }
}
