import {NTR} from "../../name-to-request";

export default class LoadParsing {
  static parsePhonogramId(response) {
    return {
      phonogramId: response[NTR.PHONOGRAM_ID],
      duration: response[NTR.DURATION]
    };
  }

  static parseFileId(response) {
    return response[NTR.FILE_ID] || ``;
  }
}
