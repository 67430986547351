import { getCursorPosition } from '../../utils/cursor-position.js';
import AbstractComponent from '../abstract-component.js';

const createSpanTemplate = (className, text, title) => {
  return (
    `<span class="${className}" ${title !== `` ? `title="${title}"` : ``}>${text}</span>`
  );
};

export default class SpanComponent extends AbstractComponent {
  constructor({className = ``, text = ``, title = ``}) {
    super();

    this._className = className;
    this._text = text;
    this._title = title;
  }

  getTemplate() {
    return createSpanTemplate(this._className, this._text, this._title);
  }

  set text(data) {
    this._text = data;
    this.getElement().innerHTML = data;
  }

  get text() {
    return this.getElement().textContent;
  }

  get textFull() {
    return this.getElement().innerHTML;
  }

  setInputHandler(handler) {
    this.getElement().addEventListener(`input`, (evt) => {
      const pos = getCursorPosition(this.getElement());
      handler(this.text, pos);
    });
  }

  setBlurHandler2(handler) {
    this.getElement().addEventListener(`blur`, () => {
      const pos = getCursorPosition(this.getElement());
      handler(pos);
    });
  }

  setFocusOutHandler2(handler) {
    this.getElement().addEventListener(`focusout`, (evt) => {
      const pos = getCursorPosition(this.getElement());
      handler(pos);
    }, true);
  }
}
