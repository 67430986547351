import {NTR} from '../../name-to-request.js';
import {getDataRequest} from '../../utils/request.js';

export const NominationParameter = {
  DIRECTION_DOWN: {[NTR.CHAIN]: NTR.DOWN},
  DIRECTION_UP: {[NTR.CHAIN]: NTR.UP},
  SUB_ALL: {[NTR.CHAIN]: NTR.ALL},
  MAX_PLAYER: {[NTR.DOCUMENT_MAX_PEOPLE]: NTR.EMPTY},
  SUBNOMINATION: {[NTR.SUB_NOMINATION]: NTR.EMPTY},
  RATING_METHOD: {[NTR.NOMINATION_RATING_METHOD]: NTR.EMPTY},
};

const ALL_NOMINATION_PARAMETERS = [
  NominationParameter.DIRECTION_DOWN,
];


export const getDataNomination = (id, size, parameters = ALL_NOMINATION_PARAMETERS) => {
  const request = {
    [NTR.GET_DATA]: {
      [NTR.NOMINATION_ID]: id,
      [NTR.SIZE]: size,
      [NTR.DATA]: getDataRequest(parameters),
    }
  };
  return JSON.stringify(request);
};
