import {checkAccess, isEmptyObject} from '../../utils/common';
import TransactionParsing from './parsing';


export default class TransactionModel {
  constructor(transactionId, data) {
    this.transactionId = transactionId;

    this.toPay = TransactionParsing.moneyAmount(data);
    this.paid = TransactionParsing.moneyAmountPaid(data);
    this.status = TransactionParsing.moneyState(data); // парсинг
    this.createDate = TransactionParsing.moneyDateAdd(data);
    this.payDate = TransactionParsing.moneyDate(data);
    this.payerInn = TransactionParsing.moneyPayerInn(data); //null
    this.payerTitle = TransactionParsing.moneyPayerTitle(data); //null
    this.link = TransactionParsing.moneyReceipt(data); //null
    this.authCode = TransactionParsing.moneyAuthCode(data);
    this.authCodeRRN = TransactionParsing.moneyAuthRRN(data);
    this.paymentSystem = TransactionParsing.moneySystem(data);

    // this.access = TransactionModel.getAccess(data);
  }

  toRAW() {
  }

  static newTransaction(transactionId, data) {
    return new TransactionModel(transactionId, data);
  }

  static parseTransactions(data) {
    if (isEmptyObject(data) || !data.people_transaction) {
      return [];
    }

    return Object.keys(data.people_transaction)
      .map((transactionId) => TransactionModel.newTransaction(transactionId, data.people_transaction[transactionId]))
      .reverse();
  }

  // static getAccess(data) {
  //   return {
  //     name: checkAccess(data.people_name),
  //     dob: checkAccess(data.people_dob),
  //     phones: checkAccess(data.people_phone_number),
  //     emails: checkAccess(data.people_email),
  //     positions: checkAccess(data.people_director),
  //     cards: checkAccess(data.people_card),
  //     auth: checkAccess(data.people_pass),
  //   };
  // }
}
