import {DOMAIN, FORMAT_TEXT} from '../const.js';
import {NTR} from '../name-to-request.js';
import Popup from '../static/popup/popup.js';

export const isDomain = (domain) => {
  return window.location.hostname.toLowerCase().indexOf(domain) === 0;
};

export const isTT = () => window.location.hostname === `${DOMAIN.TT}.ru`;
export const isZZ = () => window.location.hostname === `zz.hgfdsa.ru`;
export const isPT = () => window.location.hostname === `primetime.today`;
export const isRPT = () => window.location.hostname === `rprimetime.today`;

export const reloadPage = () => document.location.reload();
export const openPage = (link, target = `_blank`) => window.open(link, target);
export const openAbsolutePage = (link, target = `_blank`) => {
  link = link.indexOf(`http`) < 0 ? `http://${link}` : link;
  window.open(link, target);
};

export const setTitlePage = (title) => {
  document.title = title;
};

// export const getConstValue = (property) => (typeof window[property] !== `undefined`) ? window[property] : null;

export const checkAccess = (data) => !(data && data === NTR.NO_ACCESS);

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )` + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, `\\$1`) + `=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options) => {
  options = options || {};

  const expires = options.expires;

  if (typeof expires === `number` && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  let updatedCookie = `${name}=${value}`;

  for (let propName in options) {
    updatedCookie += "; " + propName;
    var propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += "=" + propValue;
    }
  }

  document.cookie = updatedCookie;
};

export const isAuthorizationUser = () => !!getCookie(`user_logged`);

export const getInstanceAbortController = () => AbortController ? new AbortController() : null;

export const getUrlSearch = (name) => {
  const params = new URLSearchParams(document.location.search.substring(1));
  return params.get(name);
};

export const getBlob = (data, formatType = FORMAT_TEXT) => {
  const datum = [data];
  return new Blob(datum, {type: formatType});
};

export const sliceBlob = (blob, start, end) => {
  if (blob.mozSlice) {
    return blob.mozSlice(start, end);
  } else if (blob.webkitSlice) {
    return blob.webkitSlice(start, end);
  } else if (blob.slice) {
    return blob.slice(start, end);
  } else {
    throw new Error(`Blob slice doesn't work!`);
  }
};

export const scrollToElement = (element) => {
  const windowHeight = document.documentElement.clientHeight;

  const scrollY = element.offsetTop - (windowHeight / 2) + (element.clientHeight / 2);

  window.scrollTo(0, scrollY);
};

export const isEmptyObject = (object) => isEmptyArray(Object.keys(object));

export const isEqualityObject = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const isEmptyArray = (array) => Array.isArray(array) && !array.length;

// export const deleteItemArray = (index, array) => [].concat(array.slice(0, index), array.slice(index + 1));

export const updateItemArray = (index, array, item) => [].concat(array.slice(0, index), item, array.slice(index + 1));

export const getIndexArray = (array, prop, value) => array.findIndex((item) => item[prop] === value);

export const getItemArray = (array, prop, value) => array.find((item) => item[prop] === value);

export const deepCopyObject = (object) => {
  const json = JSON.stringify(object);
  return JSON.parse(json);
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const timeConverter = (unixTimestamp, mode) => {
  if (!unixTimestamp) {
    return ``;
  }

  const a = new Date(unixTimestamp * 1000);
  const months = [`января`, `февраля`, `марта`, `апреля`, `мая`, `июня`, `июля`, `августа`, `сентября`, `октября`, `ноября`, `декабря`];
  const year = a.getFullYear();
  const month = months[a.getMonth()];

  let monthNum = String(a.getMonth() + 1);
  if (monthNum.length === 1) {
    monthNum = `0${monthNum}`;
  }

  const date = a.getDate();
  let dateNum = String(date);
  if (dateNum.length === 1) {
    dateNum = `0${dateNum}`;
  }

  const hour = a.getHours();
  let hourText = String(hour);
  if (hourText.length === 1) {
    hourText = `0${hourText}`;
  }

  let min = String(a.getMinutes());
  if (min.length === 1) {
    min = `0${min}`;
  }

  let sec = String(a.getSeconds());
  if (sec.length === 1) {
    sec = `0${sec}`;
  }

  switch (mode) {
    case `min:sec`:
      return `${min}:${sec}`;
    case `hour:min`:
      return `${hour}:${min}`;
    case `hour:min:sec`:
      return `${hour}:${min}:${sec}`;
    case `day:month:year`:
      return `${dateNum} ${month} ${year}`;
    case `dd.mm.yyyy hour:min`:
      return `${dateNum}.${monthNum}.${year} ${hour}:${min}`;
    case `day-month-year hour:min:sec`:
      return `${dateNum} ${month} ${year}, ${hour}:${min}:${sec}`;
  }

  return ``;
};

export const parseFilterInput = (text) => {
  let getNumber = ``;
  let flag = true;

  for (let i = 0; i < text.length; i++) {
    if (isNumeric(text[i])) {
      if (flag) {
        getNumber = getNumber + text[i];
      } else {
        if (getNumber === ``) {
          getNumber = text[i];
        } else {
          getNumber = getNumber + `,` + text[i];
        }
        flag = true;
      }
    } else {
      flag = false;
    }
  }

  return getNumber.split(`,`);
};


export const getDropDownItemsFromArray = (items) => {
  return items.map((item, i) => {
    return {
      id: i,
      text: item
    };
  });
};

export const getDropDownItemsFromObject = (obj, objToText, exceptions = []) => {
  return Object.keys(obj)
  .filter((it) => !exceptions.includes(obj[it]))
  .map((it) => {
    return {
      id: obj[it],
      text: objToText[obj[it]]
    };
  });
};

export const getUnixDate = (date) => Date.parse(date) / 1000;

export const addStylesheetRules = (rules) => {
  const styleEl = document.createElement(`style`);
  document.head.appendChild(styleEl);

  const styleSheet = styleEl.sheet;

  for (let i = 0; i < rules.length; i++) {
    let j = 1;
    let rule = rules[i];
    const selector = rule[0];
    let propStr = ``;

    if (Array.isArray(rule[1][0])) {
      rule = rule[1];
      j = 0;
    }

    for (let pl = rule.length; j < pl; j++) {
      const prop = rule[j];
      propStr += prop[0] + `: ` + prop[1] + (prop[2] ? ` !important` : ``) + `;\n`;
    }

    styleSheet.insertRule(`${selector}{${propStr}}`, styleSheet.cssRules.length);
  }
};


export const brightnessColor = (colorHEX, percent) => {
  let usePound = false;

  if (colorHEX[0] === `#`) {
    colorHEX = colorHEX.slice(1);
    usePound = true;
  }

  const num = parseInt(colorHEX, 16);

  let r = (num >> 16) + percent;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00FF) + percent;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000FF) + percent;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? `#` : ``) + (g | (b << 8) | (r << 16)).toString(16);
};


export const getFullNameText = (people) => {
  const {surname, lastname, middlename} = people;

  const names = [];

  if (surname) {
    names.push(surname);
  }

  if (lastname) {
    names.push(lastname);
  }

  if (middlename) {
    names.push(middlename);
  }

  return names.join(` `);
};

export const getListTextPosition = (positions) => positions.map((it) => it.title).join(`, `);

export const parsePositionsToText = (positions) => { // дубль getListTextPosition
  if (isEmptyArray(positions)) {
    return ``;
  }

  return positions.map((position) => position.title).join(`, `);
};

export const getPositionsFromString = (positionText) => {
  return positionText.split(`,`)
    .map((position) => {
      return {
        directorId: ``,
        title: position.trim()
      };
    });
};

export const parseNameTypeBasic = (names) => {
  return {
    surname: names[0] || ``,
    lastname: names[1] || ``,
    middlename: names[2] || ``,
  };
};

export const getNameFromText = (data = ``) => {
  const names = data.replace(/\s+/g, ` `).trim().split(` `);

  return parseNameTypeBasic(names);
};

export const getIsNoDirector = (directors) => directors.length > 0 && directors[0].peopleId === `0`;

export const getFileTitle = (name) => name.slice(0, name.lastIndexOf(`.`));

// export const getFileId = (file) => file.slice(file.indexOf(`-`) + 1, file.indexOf(`.`));

export const getChangedQuotText = (value) => String(value).replace(/"/g, `&quot;`);

export const getChangedQuotText2 = (value) => String(value).replace(/&quot;/g, `"`);

export const getChangedLessGreaterThanText = (value) => String(value).replace(/</g, `&lt;`).replace(/>/g, `&gt;`);
export const getChangedLessGreaterThanText2 = (value) => String(value).replace(/&lt;/g, `<`).replace(/&gt;/g, `>`);

export const convertPattern = (text, key, value) => text.replace(`[[${key}]]`, value);

export const getFormatFile = (name) => name.slice(name.lastIndexOf(`.`) - name.length + 1).toLowerCase();

export const highlightingTexts = (text, symbols) => {
  let result = text;
  symbols.forEach((symbol) => {
    const pos = result.toLowerCase().indexOf(symbol.toLowerCase());
    result = `${result.slice(0, pos)}<b>${result.slice(pos, pos + symbol.length)}</b>${result.slice(pos + symbol.length)}`;
  });

  return result;
};

export const checkPhone = (text) => {
  let phone = ``;
  for (let i = 0; i < text.length; i++) {
    if (isNumeric(text[i])) {
      phone = phone + text[i];
    }
  }

  return phone;
};

export const getCodeCheck = (isCheck) => isCheck ? NTR.ONE : NTR.NULL;

export const parseColor = (text) => {
  const color = text.split(`,`);
  const hue = (color[0]) ? color[0] : 0;
  const sat = (color[1]) ? color[1] : 100;
  const lit = (color[2]) ? color[2] : 50;
  return `hsl(${hue}, ${sat}%, ${lit}%)`;
};

export const stringRandom = (length = 10) => {
  let result = ``;
  const words = `0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM`;
  const maxPosition = words.length - 1;

  for (let i = 0; i < length; ++i) {
    const position = Math.floor(Math.random() * maxPosition);
    result += words.substring(position, position + 1);
  }

  return result;
};

export const getRandomColor = () => {
  const r = Math.floor(Math.random() * (256));
  const g = Math.floor(Math.random() * (256));
  const b = Math.floor(Math.random() * (256));

  return `#` + r.toString(16) + g.toString(16) + b.toString(16);
};

export const copyToClipboard = (text) => {
  // if (isTT()) {
  //   throw new Error(`Not Copied!`);
  // }
  navigator.clipboard
  .writeText(text)
  .then(() => {
    Popup.showNotification({text: `Скопировано в буфер обмена`});
    // Успех!
  })
  .catch(() => {
    // debugger;
    Popup.showNotification({text: `Не удалось скопировать в буфер обмена`});
    // throw new Error(`Not Copied!`);
  });
};



// export const copyToClipboard = (text) => {
//   const p = document.createElement(`p`);
//   p.textContent = String(text);
//   p.className = `textClipboard`;
//   document.body.append(p);

//   const selection = window.getSelection();
//   const range = document.createRange();

//   range.selectNode(p);

//   selection.removeAllRanges();
//   selection.addRange(range);

//   try {
//     document.execCommand(`copy`);
//     Popup.showNotification({text: `Скопировано в буфер обмена`});
//   } catch (err) {
//     // console.log(`Can't copy, boss`);
//     Popup.showNotification({text: `Не удалось скопировать`});
//   }

//   p.remove();
//   selection.removeAllRanges();
// };

export const clickLink = (link, download = `download`) => {
  let linkElement = document.createElement(`a`);
  linkElement.href = link;
  linkElement.download = download;
  linkElement.click();
  linkElement = null;
};

export const countDuration3 = (value) => {
  const duration = {
    minute: 0,
    second: 0
  };

  if (value < 60 && value !== 0) {
    duration.second = value;
    return duration;
  }

  duration.minute = Math.floor(value / 60);
  duration.second = value - (duration.minute * 60);

  return duration;
};

export const convertingDOB = (text) => {
  let date = text.split(`-`);
  return date[2] + `.` + date[1] + `.` + date[0];
};

export const parseMillisecondToSecond = (millisecond) => {
  if (!millisecond || millisecond === `` || millisecond === 0) {
    return 0;
  }

  return millisecond / 1000;
};

export const parseSecondToMillisecond = (second) => {
  if (!second || second === `` || second === 0) {
    return 0;
  }

  return second * 1000;
};

export const parsePercentToDecimal = (percent) => {
  if (!percent || percent === `` || percent === 0) {
    return 0;
  }

  return (percent / 100).toFixed(2);
};

export const parseDecimalToPercent = (decimal) => {
  if (!decimal || decimal === `` || decimal === 0) {
    return 0;
  }

  return decimal * 100;
};


export const parseDuration = (duration) => {
  let minute = `00`;
  let second = `00`;

  if (+duration < 60 && +duration !== 0) {
    second = duration;
  } else {
    minute = String(Math.floor(+duration / 60));
    second = String(+duration - (+minute * 60));
  }

  return {minute, second};
};

// 000 -> 00:00:00
export const convertDuration = (duration) => {
  if (!duration) {
    return ``;
  }

  let hour = `00`;
  let minut = `00`;
  let second = `00`;

  if (+duration < 60 && +duration !== 0) {
    second = duration;
  } else {
    minut = String(Math.floor(+duration / 60)); // 185 / 60 = 3
    second = String(+duration - (+minut * 60)); // 185 - (3 * 60) = 5
  }

  if (+minut > 60) {
    hour = String(Math.floor(+minut / 60));
    minut = String(+minut - (+hour * 60)); // 185 - (3 * 60) = 5
  }

  if (minut.length === 1) {
    minut = `0` + minut;
  }

  if (second.length === 1) {
    second = `0` + second;
  }

  return `${hour}:${minut}:${second}`;
  // return hour + `ч. ` + minut + `мин. ` + second + `сек. `;
};

export const parseDurationTime = (duration) => {
  const time = {
    second: 0,
    minute: 0,
    hour: 0,
    day: 0,
  };


  // сли длительность меньше 60 секунд, то выделить секунды
  if (+duration < 60 && +duration !== 0) { // если длительность меньше
    time.second = duration;
    return time;
  }

  // если длительность больше 60 минут, то выделить минуты и секунды
  if (+duration < 60 * 60) {
    time.minute = Math.floor(+duration / 60);
    time.second = +duration - (time.minute * 60);
    return time;
  }

  // если длительность больше суток, то выделить минуты и секунды и часы
  time.hour = Math.floor(+duration / (60 * 60));
  time.minute = Math.floor((+duration - (time.hour * 60 * 60)) / 60);
  time.second = Math.floor(+duration - (time.hour * 60 * 60) - (time.minute * 60));

  return time;
};

export const sizeConvert = (size) => {
  if (size === 0) {
    return `0 Мб`;
  }

  let sizeKb;
  if (size > 1024) {
    sizeKb = size / 1024;
  }

  if (sizeKb > 1024) {
    let sizeMb = sizeKb / 1024;
    return Math.round(sizeMb * 100) / 100 + ` Мб`;
  } else {
    return Math.round(sizeKb * 100) / 100 + ` Кб`;
  }
};

export const getTitlePhonogram = (name = ``) => name.slice(0, name.lastIndexOf(`.`));


const getNumeralEnding = (numeralEnding, count) => {
  if (!count && count !== 0) {
    return ``;
  }

  const string = count.toString();
  const lastChar = string.charAt(string.length - 1);

  if (isNaN(count)) {
    return ``;
  }

  if (lastChar === `1` && !(count === 11)) {
    return `${count} <span>${numeralEnding.ONE}</span>`;
  }

  if (lastChar === `2` && !(count === 12)
    || lastChar === `3` && !(count === 13)
    || lastChar === `4` && !(count === 14)) {
    return `${count} <span>${numeralEnding.SEVERAL}</span>`;
  }

  return `${count} <span>${numeralEnding.MANY}</span>`;
};

export const getPerformanceNumeralEnding = (count) => {
  const numeralEnding = {
    ONE: `выступление`,
    SEVERAL: `выступления`,
    MANY: `выступлений`
  };
  return getNumeralEnding(numeralEnding, count);
};


export const getIsWidthScreenMoreThan = (maxWidth) => {
  const widthScreen = document.documentElement.clientWidth;

  return widthScreen >= maxWidth;
};

export const hexToRgb = (hex, opacity) => {
  const bigint = parseInt(hex.slice(1), 16);

  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  if (opacity === 1) {
    return `rgb(${r}, ${g}, ${b})`;
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
