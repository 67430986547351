import AbstractComponent from '../abstract-component.js';

const createUlTemplate = (className) => {
  return (
    `<ul class="${className}"></ul>`
  );
};

export default class UlComponent extends AbstractComponent {
  constructor(className = ``) {
    super();

    this._className = className;
  }

  getTemplate() {
    return createUlTemplate(this._className);
  }
}
