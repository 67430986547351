import {DEFAULT_FULL_NAME, EmailStatus, PaymentSystem} from "../../const";
import {NTR} from "../../name-to-request";


const getEmailStatus = (data) => {
  if (!data) {
    return EmailStatus.UNDEFINED;
  }

  switch (data) {
    case `null`:
      return EmailStatus.UNDEFINED;
    case `1`:
      return EmailStatus.OK;
    case `550`:
      return EmailStatus.NOT_EXIST;
    case `552`:
      return EmailStatus.FULL;
    default:
      return EmailStatus.UNDEFINED;
  }
};

const getPaymentSystem = (system) => {
  if (system === PaymentSystem.YANDEX) {
    return PaymentSystem.UKASSA;
  }

  return system;
};

export default class PeopleParsing {
  static peopleIdData(response) {
    return response[NTR.PEOPLE_ID];
  }

  static mailIdData(response) {
    return response[NTR.MAIL_ID];
  }

  static peopleCard(data) {
    const peopleCard = data.people_card;

    if (!peopleCard || peopleCard === NTR.NO_ACCESS) {
      return [];
    }

    const cardIds = Object.keys(peopleCard);

    return cardIds.map((cardId) => {
      return {
        cardId,
        cardNumber: peopleCard[cardId].card_number,
        paymentSystem: getPaymentSystem(peopleCard[cardId].payment_system),
        isActive: peopleCard[cardId].card_state === NTR.ONE,
        isAttachmentContest: false,
        isAttachmentAll: false,
      };
    });
  }

  static peopleDirector(data) {
    const peopleDirector = data.people_director;

    if (!peopleDirector || peopleDirector === NTR.NO_ACCESS) {
      return [];
    }

    return peopleDirector.split(`,`)
      .map((position) => {
        return {
          directorId: ``,
          title: position.trim()
        };
      });
  }

  static peopleDob(data) {
    // приходит в формате 1987-10-16
    const peopleDob = data.people_dob;

    return !peopleDob || peopleDob === NTR.NO_ACCESS ? `` : peopleDob;
  }

  static peopleEmail(data) {
    const peopleEmail = data.people_email;

    if (!peopleEmail || peopleEmail === NTR.NO_ACCESS) {
      return [];
    }

    const mailIds = Object.keys(peopleEmail);

    return mailIds.map((mailId) => {
      const dataEmail = peopleEmail[mailId];
      return {
        state: `current`,
        mailId,
        mail: dataEmail.email || ``,
        status: getEmailStatus(dataEmail.status),
      };
    });
  }

  static peopleName(data) {
    const peopleName = data.people_name;

    return !peopleName || peopleName === NTR.NO_ACCESS ? DEFAULT_FULL_NAME : peopleName;
  }

  static peopleSurname(data) {
    const peopleName = data.people_name;

    return !peopleName || peopleName === NTR.NO_ACCESS || !peopleName.surname ? `` : peopleName.surname;
  }

  static peopleLastname(data) {
    const peopleName = data.people_name;

    return !peopleName || peopleName === NTR.NO_ACCESS || !peopleName.lastname ? `` : peopleName.lastname;
  }

  static peopleMiddlename(data) {
    const peopleName = data.people_name;

    return !peopleName || peopleName === NTR.NO_ACCESS || !peopleName.middlename ? `` : peopleName.middlename;
  }

  static peoplePass(data) {
    const peoplePass = data.people_pass;

    if (!peoplePass || peoplePass === NTR.NO_ACCESS) {
      return false;
    }

    return peoplePass === `yes`;
  }

  static peoplePhone(data) {
    const peoplePhone = data.people_phone_number;

    if (!peoplePhone || peoplePhone === NTR.NO_ACCESS) {
      return [];
    }

    const phoneIds = Object.keys(peoplePhone);

    return phoneIds.map((phoneId) => {
      return {
        state: `current`,
        phoneId,
        phone: peoplePhone[phoneId],
      };
    });
  }

  static peopleAlias(data) {
    const alias = data.people_alias;

    if (!alias || alias === NTR.NO_ACCESS) {
      return [];
    }

    const surnameLanguages = Object.keys(alias.surname);
    const lastnameLanguages = Object.keys(alias.lastname);
    const middlenameLanguages = Object.keys(alias.middlename);
    const languages = [].concat(surnameLanguages, lastnameLanguages, middlenameLanguages);
    const languagesSet = new Set(languages);

    return Array.from(languagesSet).map((lang) => {
      return {
        language: lang,
        surname: alias.surname[lang] || ``,
        lastname: alias.lastname[lang] || ``,
        middlename: alias.middlename[lang] || ``,
      };
    });
  }
}
