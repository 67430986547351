export default class TerminalModel {
  constructor(terminalId, data) {
    this.terminalId = terminalId;

    this.surname = data.surname || ``;
    this.page = data.page || ``;
  }

  toRAW() {
  }

  static newTerminal(terminalId, data) {
    return new TerminalModel(terminalId, data);
  }

  static clone(terminalId, data) {
    return new TerminalModel(terminalId, data.toRAW());
  }
}
