import EmailInputComponent from '../component/input/email-input/email-input';
import Popup, {ContentType, PopupSize} from '../static/popup/popup.js';
import LoaderController from './loader.js';
import InstructionReminderPasswordComponent from '../component/instruction-reminder-password/instruction-reminder-password.js';
import EmailTemplateData from '../data/email-template/email-template.js';

export default class ReminderPasswordController {
  constructor({api, onSentPasswordHandler = () => {}, onClosePopupHandler = () => {}}) {
    this._api = api;
    this._onSentPasswordHandler = onSentPasswordHandler;
    this._onClosePopupHandler = onClosePopupHandler;

    this._loader = new LoaderController();

    this._email = ``;

    this._onSendPasswordHandler = this._onSendPasswordHandler.bind(this);
    this._onInputHandler = this._onInputHandler.bind(this);
    this._onSuccessHandler = this._onSuccessHandler.bind(this);
    this._onSentPassword = this._onSentPassword.bind(this);
  }

  show() {
    const instructionComponent = new InstructionReminderPasswordComponent();

    Popup.showCustom({
      size: PopupSize.MEDIUM,
      title: `Напоминание пароля`,
      onClosePopupHandler: this._onClosePopupHandler,
      contents: [
        {
          type: ContentType.TEXT,
          content: `Введите Ваш e-mail, указанный при регистрации. Пароль будет повторно отправлен на Вашу почту.`,
        },
        {
          type: ContentType.INPUT,
          content: this._getEmailInputComponent(),
        },
        {
          type: ContentType.FRAGMENT,
          content: instructionComponent.getElement(),
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: `Отмена`,
              isClose: true,
            },
            {
              title: `Отправить`,
              onClickHandler: this._onSendPasswordHandler,
              isRequired: true,
            }
          ],
        }
      ]
    });
  }

  _getEmailInputComponent() {
    const inputComponent = new EmailInputComponent({
      label: `E-mail`,
      isRequired: true,
    });

    inputComponent.setInputHandler(this._onInputHandler);

    return inputComponent;
  }

  _onSendPasswordHandler() {
    this._loader.show();

    new EmailTemplateData({api: this._api})
      .sendPass(this._email)
      .then(() => this._onSuccessHandler())
      .finally(() => this._loader.hide());
  }

  _onInputHandler(value) {
    this._email = value;
  }

  _onSuccessHandler() {
    Popup.showCustom({
      size: PopupSize.SMALL,
      title: `Восстановление пароля`,
      contents: [
        {
          type: ContentType.TEXT,
          content: `На вашу электронную почту <b>${this._email}</b> было отправлено письмо с паролем.`,
        },
        {
          type: ContentType.TEXT,
          content: `Пожалуйста, проверьте электронную почту.`,
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: `OK`,
              onClickHandler: this._onSentPassword,
              isClose: true,
            },
          ],
        }
      ]
    });
  }

  _onSentPassword() {
    this._onSentPasswordHandler(this._email);
  }
}
