const _modules = {
  editPerformance: () => import('./lazy-load/edit-performance.js'),
  popup: () => import('./lazy-load/popup.js'),

  registrationPage: () => import(`./page/contest-settings-page/constructor-registration-page-section.js`),
  contestDates: () => import(`./page/contest-settings-page/dates-section.js`),
  colorScheme: () => import(`./page/contest-settings-page/color-section.js`),
  criterias: () => import(`./page/contest-settings-page/criterias-section.js`),
  nominationTable: () => import(`./page/contest-settings-page/nominations-section.js`),
  ageCategoryTable: () => import(`./page/contest-settings-page/age-categories-section.js`),
  ratingMethod: () => import(`./page/contest-settings-page/rating-method-section.js`),
  refereeTable: () => import(`./page/contest-settings-page/referees-section.js`),
  soundmans: () => import(`./page/contest-settings-page/soundmans-section.js`),
  comperes: () => import(`./page/contest-settings-page/comperes-section.js`),
  roles: () => import(`./page/contest-settings-page/roles-section.js`),
  terminals: () => import(`./page/contest-settings-page/terminals-section.js`),
  documents: () => import(`./page/contest-settings-page/documents-section.js`),
  userFields: () => import(`./page/contest-settings-page/user-fields-section.js`),
  mailTemplates: () => import(`./page/contest-settings-page/mail-templates-section.js`),
  myActions: () => import(`./page/contest-settings-page/my-actions-section.js`),
  contestAdmPage: () => import(`./page/contest-settings-page/constructor-contestadm-page-section.js`),
  resultsPage: () => import(`./page/contest-settings-page/results-page-setting-section.js`),
  orderTurnPage: () => import(`./page/contest-settings-page/order-turn-page-setting-section.js`),
  refereePage: () => import(`./page/contest-settings-page/referee-page-setting-section.js`),
  backdropPage: () => import(`./page/contest-settings-page/backdrop-page-setting-section.js`),
  soundmanPage: () => import(`./page/contest-settings-page/soundman-page-setting-section.js`),
  comperePage: () => import(`./page/contest-settings-page/compere-page-setting-section.js`),
  scenaPage: () => import(`./page/contest-settings-page/scena-page-setting-section.js`),
  titerPage: () => import(`./page/contest-settings-page/titer-page-setting-section.js`),
  semaphorePage: () => import(`./page/contest-settings-page/semaphore-page-setting-section.js`),
  customScripts: () => import(`./page/contest-settings-page/custom-scripts-page-section.js`),
  mailServer: () => import(`./page/contest-settings-page/mail-server-section.js`),
  paymentSystem: () => import(`./page/contest-settings-page/payment-system-section.js`),
  defaultLang: () => import(`./page/contest-settings-page/default-lang-section.js`),
  documentTest: () => import(`./page/contest-settings-page/document-test-section.js`),
  domain: () => import(`./page/contest-settings-page/domain-section.js`),
};

export default class LazyRouterController {
  static get modules() {
    return _modules;
  }
}

