import PerformanceParsing from '../data/performance/parsing';
import ParsingData from '../static/parsing-data/parsing-data';
import {checkAccess, getIsNoDirector, isEmptyObject} from '../utils/common';


export default class PerformanceModel {
  constructor(data) {
    this.performanceId = data.performance_id;
    this.type = `performance`;
    this.performanceTitle = ParsingData.performanceTitle(data);
    this.performanceCid = ParsingData.performanceCid(data);
    this.isBlocked = ParsingData.unblock(data);
    this.turnId = ParsingData.performanceTurnId(data);
    this.groupId = ParsingData.performanceGroup(data);
    this.timeStart = ParsingData.performanceTimeStart(data);
    this.beforeText = ParsingData.performanceEntryBefore(data);
    this.afterText = ParsingData.performanceEntryAfter(data);

    this.ageCategory = ParsingData.performanceAgeCategory(data);
    this.nomination = ParsingData.performanceNomination(data);
    this.qualification = ParsingData.performanceQualification(data);

    this.duration = ParsingData.performanceTimeFull(data);
    this.money = ParsingData.performanceMoney(data);

    this.isArchive = ParsingData.performanceArchive(data);
    this.city = ParsingData.performanceCity(data);
    this.cityId = ParsingData.performanceCityId(data);
    this.team = ParsingData.performanceTeam(data);
    this.organization = ParsingData.performanceOrganization(data);

    this.directors = PerformanceParsing.performanceDirectorNew(data);
    this.isNoDirector = getIsNoDirector(this.directors); // указание что руководителя нет

    this.directorsData = {// переделать
      directors: this.directors,
      isNoDirector: this.isNoDirector, // указание что руководителя нет
    };

    this.playersData = {
      players: ParsingData.performancePlayer(data),
      count: ParsingData.performancePlayerCount(data),
    };
    this.players = ParsingData.performancePlayer(data);
    this.countPlayers = ParsingData.performancePlayerCount(data);

    this.contactPeople = ParsingData.performanceContact(data);

    this.phonogramsData = {
      phonograms: ParsingData.performancePhonograms(data),
      generalPhonogramStatus: ParsingData.phonogramGeneralStatus(data),
    };
    this.phonograms = ParsingData.performancePhonograms(data);
    this.generalPhonogramStatus = ParsingData.phonogramGeneralStatus(data);

    this.files = ParsingData.performanceContent(data);

    this.ratings = ParsingData.performanceRating(data);
    this.ratingReferees = ParsingData.performanceAllRating(data); // для запроса "get_rating: all"
    this.ratingSum = ParsingData.performanceRatingSum(data);

    this.prizeId = ParsingData.performancePrize(data);
    this.prizeManualId = ParsingData.performancePrizeManual(data);
    this.prints = ParsingData.documentPrinted(data);

    this.notes = ParsingData.performanceNote(data);

    this.userField = ParsingData.performanceUserData(data);

    this.realTime = PerformanceParsing.performanceReal(data);


    this.access = PerformanceModel.getAccess(data);


    // // добавить в блок toRaw
    // this.payment = data.performance_money || {};
    // this.contactPeopleId = data.performance_contact || null;
    // this.registratorPeopleId = (data.performance_add_people && data.performance_add_people != NTR.NO_ACCESS) ? data.performance_add_people : null;

    // this.ageMin = (data.player_age_min && data.player_age_min != "0") ? data.player_age_min : "";
    // this.ageMax = (data.player_age_max && data.player_age_max != "0") ? data.player_age_max : "";
    // this.nameCountPlayers = (data.player_count_name_show && data.player_count_name_show == '0') ? false : true;
    // this.dateCreation = data.performance_creation_date || '';
    // this.comment = data.performance_note || {};

    // //

    // // this.isBlocked = isDomain(DOMAIN.TT) ? true : false;

    // this.notes = datum.notes;
    // this.links = (data.performance_link && data.performance_link != NTR.NO_ACCESS) ? data.performance_link : [];

  }

  toRAW() {
    return {};
  }

  static newPerformance(data) {
    return new PerformanceModel(data);
  }

  static parsePerformance(data) {
    if (!data.data[0]) {
      return null;
    }
    return PerformanceModel.newPerformance(data.data[0]);
  }

  // проверка на пустоту инфы от сервера
  // if (isEmptyObj(request.data)) {
  //   debugger;
  //   return null;
  // }

  static parsePerformances(data) {
    // const performances = isEmptyObject(data.data) ? [] : data.data.map((performance) => PerformanceModel.newPerformance(performance));
    const performances = isEmptyObject(data.data) ? [] : data.data.map((performance) => PerformanceModel.newPerformance(performance));
    const count = !data.performance_count ? 0 : data.performance_count;

    return {performances, count};
  }

  static getAccess(data) {
    return {
      isBlocked: checkAccess(data.unblock),
      performanceCid: checkAccess(data.performance_cid),
      turnId: checkAccess(data.performance_turn_id),
      group: checkAccess(data.performance_group),
      timeStart: checkAccess(data.performance_time_start),
      beforeText: checkAccess(data.performance_entry),
      afterText: checkAccess(data.performance_entry),
      ageCategory: checkAccess(data.performance_age_category),
      nomination: checkAccess(data.performance_nomination),
      qualification: checkAccess(data.performance_qualification),
      duration: checkAccess(data.performance_time_full),
      performanceTitle: checkAccess(data.performance_title),
      money: checkAccess(data.performance_money),
      isArchive: checkAccess(data.performance_archive),
      city: checkAccess(data.performance_city),
      cityId: checkAccess(data.performance_city_id),
      team: checkAccess(data.performance_team_title),
      organization: checkAccess(data.performance_organization_title),
      directors: checkAccess(data.performance_director),
      players: checkAccess(data.performance_player),
      countPlayers: checkAccess(data.performance_player_count),
      contactPeople: checkAccess(data.performance_contact),
      phonograms: checkAccess(data.phonogram_title),
      generalPhonogramStatus: checkAccess(data.performance_phonogram_status),
      files: checkAccess(data.performance_content),
      ratings: checkAccess(data.performance_rating),
      ratingReferees: checkAccess(data.performance_rating),
      ratingSum: checkAccess(data.performance_rating_sum),
      prizeId: checkAccess(data.performance_prize),
      prizeManualId: checkAccess(data.performance_prize_manual),
      // isPrinted: checkAccess(data.document_printed),
      prints: checkAccess(data.document_printed),
      notes: checkAccess(data.performance_note),
    };
  }

  static clone(data) {
    return new PerformanceModel(data.toRAW());
  }

  // static parsePerformance(data, datum) {
  //   return new PerformanceModel(data, datum);
  // }

  // static parsePerformances(request) {
  //   const performances = request.data.map((performance) => {

  //     const money = performance.performance_money ? getMoneys(performance.performance_money) : {};

  //     const notes = getNotes(performance.performance_note);


  //     return PerformanceModel.parsePerformance(performance, {phonograms, generalPhonogramStatus, directors, players, contactPeople, ratings, money, notes, team, organization, files, access});
  //   });

  //   return {count: request.performance_count, performances};
  // }
}
