import AbstractComponent from '../abstract-component.js';

const createSectionTemplate = (className) => {
  return (
    `<section class="${className}"></section>`
  );
};

export default class SectionComponent extends AbstractComponent {
  constructor(className) {
    super();

    this._className = className;
  }

  getTemplate() {
    return createSectionTemplate(this._className);
  }
}
