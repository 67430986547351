import LoadProgressComponent from "../../component/load-progress/load-progress";
import Popup from "../../static/popup/popup";
import {getInstanceAbortController} from "../../utils/common";
import {remove, render, RenderPosition} from "../../utils/render";

export default class LoadController {
  constructor() {
    this._abortController = getInstanceAbortController(); // для отмены загрузки

    this._loadProgressComponent = null;

    this._onProgressLoadHandler = this._onProgressLoadHandler.bind(this);
    this._onAbortHandler = this._onAbortHandler.bind(this);
  }

  get isAbort() {
    return !!this._abortController;
  }

  _renderProgress() {
    this._loadProgressComponent = new LoadProgressComponent(this._files, this.isAbort);
    if (this._container) {
      render(this._container, this._loadProgressComponent.getElement(), RenderPosition.AFTERBEGIN);
    }
    this._loadProgressComponent.setAbortButtonClickHandler(this._onAbortHandler);
  }

  _removeProgress() {
    if (!this._loadProgressComponent) {
      return;
    }
    remove(this._loadProgressComponent);
    this._loadProgressComponent = null;
  }

  _getAbortSignal() {
    return this.isAbort ? this._abortController.signal : null;
  }

  _abort() {
    this._abortController.abort();
  }

  _showProgressLoad(value, max) {
    let progress = Math.floor((value / max) * 1000) / 10;
    if (progress > 100) {
      progress = 100;
    }

    this._loadProgressComponent.changeProgress(this._orderId, value, `${progress}%`);
  }

  _onProgressLoadHandler(value, max) {
    this._showProgressLoad(value, max);
  }

  _onAbortHandler() {
    this._abort();
    // this._removeProgress();
    Popup.showInfo({text: `Загрузка отменена!`});
  }
}
