import AbstractComponent from "../abstract-component";

const createNotificationTemplate = (text) => {
  return (
    `<section class="notification fadeIn">
      <p class="notification__text">${text}</p>
    </section>`
  );
};

export default class NotificationComponent extends AbstractComponent {
  constructor({text = ``}) {
    super();

    this._text = text;
  }

  getTemplate() {
    return createNotificationTemplate(this._text);
  }

  updateText(value) {
    this._text = value;
    this.getElement().querySelector(`p`).innerHTML = this._text;
  }
}
