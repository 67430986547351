import LoaderComponent from '../component/loader/loader';
import {render, remove, RenderPosition} from '../utils/render';

export default class LoaderController {
  constructor(container) {
    this._container = container || document.body;
    this._loaderComponent = new LoaderComponent(container);
  }

  show() {
    render(this._container, this._loaderComponent.getElement(), RenderPosition.AFTERBEGIN);
  }

  hide() {
    remove(this._loaderComponent);
  }
}
