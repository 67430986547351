import {Service} from "../../const";
import {PopupSize} from "../../static/popup/popup";
import ServiceController from "../service";

export default class ActivityServiceController extends ServiceController {
  constructor(link) {
    super();

    this._link = link;
    this._service = Service.ACTIVITY;
    this._popupSize = PopupSize.LARGE;
  }
}
