import {NTR} from "../../name-to-request";
import {getCodeCheck} from "../../utils/common";
import {getDataRequest} from "../../utils/request";
import {ALL_PEOPLE_PARAMETERS} from "./parameter";

export default class PeopleRequest {
  static getOne({peopleId, parameters = ALL_PEOPLE_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static name({peopleId, surname = ``, lastname = ``, middlename = ``}) {
    return {
      [NTR.SET_PEOPLE_NAME]: {
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.SURNAME]: surname,
        [NTR.LASTNAME]: lastname,
        [NTR.MIDDLENAME]: middlename,
      }
    };
  }

  static addEmail({peopleId, newMail}) {
    return {
      [NTR.SET_PEOPLE_EMAIL]: {
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.EMAIL]: newMail,
      }
    };
  }

  static changeEmail({peopleId, oldMail, newMail}) {
    return {
      [NTR.SET_PEOPLE_EMAIL]: {
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.EMAIL]: oldMail,
        [NTR.NEW_EMAIL]: newMail,
      }
    };
  }

  static deleteEmail({peopleId, oldMail}) {
    return {
      [NTR.SET_PEOPLE_EMAIL]: {
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.EMAIL]: oldMail,
        [NTR.DELETE]: NTR.ONE,
      }
    };
  }

  static addPhone({peopleId, newPhone}) {
    return {
      [NTR.SET_PEOPLE_PHONE_NUMBER]: {
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.PHONE_NUMBER]: newPhone,
      }
    };
  }

  static changePhone({peopleId, oldPhone, newPhone}) {
    return {
      [NTR.SET_PEOPLE_PHONE_NUMBER]: {
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.PHONE_NUMBER]: oldPhone,
        [NTR.NEW_PHONE_NUMBER]: newPhone,
      }
    };
  }

  static deletePhone({peopleId, oldPhone}) {
    return {
      [NTR.SET_PEOPLE_PHONE_NUMBER]: {
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.PHONE_NUMBER]: oldPhone,
        [NTR.DELETE]: NTR.ONE,
      }
    };
  }

  static getAllCard() {
    return {
      [NTR.GET_DATA]: {
        [NTR.PEOPLE_CARD]: NTR.ALL,
      }
    };
  }

  static getContestCard({paymentRole}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PEOPLE_CARD]: NTR.CONTEST,
        [NTR.PAYMENT_ROLE]: paymentRole,
      }
    };
  }

  static setStatusCard({cardId, isActive}) {
    return {
      [NTR.SET_PEOPLE_CARD]: {
        [NTR.CARD_ID]: cardId,
        [NTR.STATUS]: getCodeCheck(isActive),
      }
    };
  }

  static deleteCard({cardId}) {
    return {
      [NTR.SET_PEOPLE_CARD]: {
        [NTR.CARD_ID]: cardId,
        [NTR.STATUS]: `100`,
      }
    };
  }

  static delegationCard({cardId, peopleId}) {
    return {
      [NTR.SET_PEOPLE_CARD]: {
        [NTR.CARD_ID]: cardId,
        [NTR.STATUS]: NTR.ONE,
        [NTR.PEOPLE_ID]: peopleId,
      }
    };
  }

  static getTransaction({filter}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PEOPLE_TRANSACTION]: NTR.EMPTY,
        [NTR.FILTER]: filter,
      }
    };
  }

  static setPeopleContest({contestId, role}) {
    return {
      [NTR.SET_PEOPLE_CONTEST]: {
        [NTR.CONTEST_ID]: contestId,
        [NTR.CONTEST_ROLE]: role,
      }
    };
  }

  static setAuthEmail({peopleId, email}) {
    return {
      [NTR.SET_PEOPLE_AUTH_EMAIL]: {
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.EMAIL]: email,
      }
    };
  }
}
