import AbstractComponent from '../abstract-component.js';

const createInstructionReminderPasswordTemplate = () => {
  return (
    `<ul>
      <li>Если письма с паролем нет во Входящих письмах, проверьте папки «СПАМ» и «Рассылки».</li>
      <li>Введите в поисковой строке Вашего почтового ящика адрес support@primetime.today</li>
      <li>Чтобы исключить ошибку при вводе, скопируйте логин и пароль из письма.</li>
      <li>Если ничего не помогло, пишите или звоните в техподдержку. Чат техподдержки — в&nbsp;левом нижнем углу каждой страницы, мы Вам обязательно поможем!</li>
    </ul>`
  );
};

export default class InstructionReminderPasswordComponent extends AbstractComponent {
  getTemplate() {
    return createInstructionReminderPasswordTemplate();
  }
}
