import {TransactionStatus} from "../../const";
import {NTR} from "../../name-to-request";


const getTransactionStatus = (state) => {
  switch (state) {
    case `0`:
      return TransactionStatus.PROCESSING;
    case `1`:
      return TransactionStatus.PAID;
    case `-1`:
      return TransactionStatus.CANCELED;
    default:
      return TransactionStatus.UNKNOWN;
  }
};

export default class TransactionParsing {
  static moneyAmount(data) {
    const moneyAmount = data.money_amount;

    if (!moneyAmount || moneyAmount === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return +moneyAmount;
  }

  static moneyAmountPaid(data) {
    const moneyAmountPaid = data.money_amount_paid;

    if (!moneyAmountPaid || moneyAmountPaid === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return +moneyAmountPaid;
  }

  static moneyState(data) {
    const moneyState = data.money_state;

    if (!moneyState || moneyState === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return getTransactionStatus(moneyState);
  }

  static moneyDateAdd(data) {
    const moneyDateAdd = data.money_date_add;

    if (!moneyDateAdd || moneyDateAdd === NTR.NO_ACCESS || !+moneyDateAdd) {
      return NTR.EMPTY;
    }

    return +moneyDateAdd;
  }

  static moneyDate(data) {
    const moneyDate = data.money_date;

    if (!moneyDate || moneyDate === NTR.NO_ACCESS || !+moneyDate) {
      return NTR.EMPTY;
    }

    return +moneyDate;
  }

  static moneyPayerInn(data) {
    const moneyPayerInn = data.money_payer_inn;

    if (!moneyPayerInn || moneyPayerInn === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return moneyPayerInn;
  }

  static moneyPayerTitle(data) {
    const moneyPayerTitle = data.money_payer_title;

    if (!moneyPayerTitle || moneyPayerTitle === NTR.NO_ACCESS || moneyPayerTitle === `NULL`) {
      return NTR.EMPTY;
    }

    return moneyPayerTitle;
  }

  static moneyReceipt(data) {
    const moneyReceipt = data.money_receipt;

    if (!moneyReceipt || moneyReceipt === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return moneyReceipt;
  }

  static moneyAuthRRN(data) {
    const moneyAuthRRN = data.money_auth_rrn;

    if (!moneyAuthRRN || moneyAuthRRN === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return moneyAuthRRN;
  }

  static moneyAuthCode(data) {
    const moneyAuthCode = data.money_auth_code;

    if (!moneyAuthCode || moneyAuthCode === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return moneyAuthCode;
  }

  static moneySystem(data) {
    const moneySystem = data.money_system;

    if (!moneySystem || moneySystem === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return moneySystem;
  }
}
