import FooterPopDataTransferComponent from "../../component/footer-pop-data-transfer/footer-pop-data-transfer";
import {Service} from "../../const";
import {PopupSize} from "../../static/popup/popup";
import ServiceController from "../service";

export default class DataTransferServiceController extends ServiceController {
  constructor() {
    super();

    this._service = Service.DATA_TRANSFER;
    this._popupSize = PopupSize.MEDIUM;
  }

  _getContent() {
    return new FooterPopDataTransferComponent();
  }
}
