import AbstractComponent from '../abstract-component.js';

const createDivTemplate = (className) => {
  return (
    `<div class="${className}"></div>`
  );
};

export default class DivComponent extends AbstractComponent {
  constructor(className = ``) {
    super();

    this._className = className;
  }

  getTemplate() {
    return createDivTemplate(this._className);
  }
}
