export default class CompereModel {
  constructor(compereId, data) {
    this.compereId = compereId;

    this.lastname = data.lastname || ``;
    this.surname = data.surname || ``;
    this.middlename = data.middlename || ``;
  }

  toRAW() {
  }

  static newCompere(compereId, data) {
    return new CompereModel(compereId, data);
  }

  static clone(compereId, data) {
    return new CompereModel(compereId, data.toRAW());
  }
}
