import {MailTemplate, MailTemplateId} from "../../const/mail-template";
import {NTR} from "../../name-to-request";

export default class EmailTemplateRequest {
  static sendPassword({email}) {
    return {
      [NTR.SEND_EMAIL]: {
        [NTR.TEMPLATE_ID]: MailTemplateId[MailTemplate.PASSWORD],
        [NTR.MAIL]: email,
      }
    };
  }

  static sendMail({template, email}) {
    return {
      [NTR.SEND_EMAIL]: {
        [NTR.TEMPLATE_NAME]: template,
        [NTR.MAIL]: email,
      }
    };
  }

  static sendMailId({template, mailId, message}) {
    return {
      [NTR.SEND_EMAIL]: {
        [NTR.TEMPLATE_NAME]: template,
        [NTR.MAIL_ID]: mailId,
        [NTR.USER_MESSAGE]: message,
      }
    };
  }

  static sendCustom({template, peopleId, performanceId, message}) {
    return {
      [NTR.SEND_EMAIL]: {
        [NTR.TEMPLATE_NAME]: template,
        [NTR.MAIL_ID]: ``,
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.USER_MESSAGE]: message,
      }
    };
  }

  static sendCustomNew({templateId, peopleId, performanceId, message}) {
    return {
      [NTR.SEND_EMAIL]: {
        [NTR.TEMPLATE_ID]: templateId,
        [NTR.MAIL_ID]: ``,
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.USER_MESSAGE]: message,
      }
    };
  }

  static sendCustom2({template, mailId, performanceId, message}) {
    return {
      [NTR.SEND_EMAIL]: {
        [NTR.TEMPLATE_NAME]: template,
        [NTR.MAIL_ID]: mailId,
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.USER_MESSAGE]: message,
      }
    };
  }

  static sendCustomNew2({templateId, mailId, performanceId, message}) {
    return {
      [NTR.SEND_EMAIL]: {
        [NTR.TEMPLATE_ID]: templateId,
        [NTR.MAIL_ID]: mailId,
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.USER_MESSAGE]: message,
      }
    };
  }
}
