import {NTR} from '../../name-to-request.js';
import {getDataRequest} from '../../utils/request.js';

export const PerformanceParameterOld = {
  ARCHIVE: {[NTR.PERFORMANCE_ARCHIVE]: NTR.EMPTY},
  AGE_CATEGORY: {[NTR.PERFORMANCE_AGE_CATEGORY]: NTR.EMPTY},

  CID: {[NTR.PERFORMANCE_CID]: NTR.EMPTY},
  CITY: {[NTR.PERFORMANCE_CITY]: NTR.EMPTY},
  COMMENTS: {[NTR.PERFORMANCE_NOTE]: NTR.EMPTY},
  CONTACT: {[NTR.PERFORMANCE_CONTACT]: NTR.EMPTY},

  DATE_CREATION: {[NTR.PERFORMANCE_CREATION_DATE]: NTR.EMPTY},
  DIRECTORS: {[NTR.PERFORMANCE_DIRECTOR]: NTR.EMPTY},
  DOCUMENT_PRINTED: {[NTR.DOCUMENT_PRINTED]: NTR.EMPTY},
  DURATION: {[NTR.PERFORMANCE_TIME_FULL]: NTR.EMPTY},

  ENTRY: {[NTR.PERFORMANCE_ENTRY]: NTR.EMPTY},

  FILES: {[NTR.PERFORMANCE_CONTENT]: NTR.EMPTY},

  GROUP: {[NTR.PERFORMANCE_GROUP]: NTR.EMPTY},

  LINK_ORIGINAL: {[NTR.PERFORMANCE_LINK]: NTR.SOURCE},

  NOMINATION: {[NTR.PERFORMANCE_NOMINATION]: NTR.EMPTY},

  ORGANIZATION: {[NTR.PERFORMANCE_ORGANIZATION_TITLE]: NTR.EMPTY},
  ORGANIZATION_CITY: {[NTR.ORGANIZATION_CITY]: NTR.EMPTY},
  ORGANIZATION_CITY_PRINT: {[NTR.ORGANIZATION_CITY_PRINT]: NTR.EMPTY},
  ORGANIZATION_ID: {[NTR.PERFORMANCE_ORGANIZATION_ID]: NTR.EMPTY},

  PAYMENT: {[NTR.PERFORMANCE_MONEY]: NTR.EMPTY},
  PHONOGRAM_DATE: {[NTR.PHONOGRAM_DATE]: NTR.EMPTY},
  PHONOGRAM_DURATION: {[NTR.PHONOGRAM_DURATION]: NTR.EMPTY},
  PHONOGRAM_SIZE: {[NTR.PHONOGRAM_SIZE]: NTR.EMPTY},
  PHONOGRAM_START_WITH_POINT: {[NTR.PHONOGRAM_START_WITH_POINT]: NTR.EMPTY},
  PHONOGRAM_STATUS: {[NTR.PERFORMANCE_PHONOGRAM_STATUS]: NTR.EMPTY},
  PHONOGRAM_TITLE: {[NTR.PHONOGRAM_TITLE]: NTR.EMPTY},
  PLAYERS: {[NTR.PERFORMANCE_PLAYER]: NTR.EMPTY},
  PLAYERS_AGE_RANGE: {[NTR.PERFORMANCE_AGE_RANGE]: NTR.EMPTY},
  PLAYERS_COUNT: {[NTR.PERFORMANCE_PLAYER_COUNT]: NTR.EMPTY},
  PLAYERS_NAME_COUNT: {[NTR.PLAYERS_COUNT_NAME_SHOW]: NTR.EMPTY},
  PRIZE: {[NTR.PERFORMANCE_PRIZE]: NTR.EMPTY},
  PRIZE_MANUAL: {[NTR.PERFORMANCE_PRIZE_MANUAL]: NTR.EMPTY},

  RATING: {[NTR.PERFORMANCE_RATING]: NTR.EMPTY},
  RATING_ALL: {[NTR.PERFORMANCE_RATING]: NTR.ALL},
  RATING_SUM: {[NTR.PERFORMANCE_RATING_SUM]: NTR.EMPTY},
  REAL_TIME: {[NTR.PERFORMANCE_REAL_TIME]: NTR.EMPTY},
  REGISTRATOR: {[NTR.PERFORMANCE_ADD_PEOPLE]: NTR.EMPTY},

  TEAM: {[NTR.PERFORMANCE_TEAM_TITLE]: NTR.EMPTY},
  TEAM_CITY: {[NTR.PERFORMANCE_TEAM_CITY]: NTR.EMPTY},
  TEAM_CITY_PRINT: {[NTR.TEAM_CITY_PRINT]: NTR.EMPTY},
  TEAM_ID: {[NTR.PERFORMANCE_TEAM_ID]: NTR.EMPTY},
  TIME_START: {[NTR.PERFORMANCE_TIME_START]: NTR.EMPTY},
  TITLE: {[NTR.PERFORMANCE_TITLE]: NTR.EMPTY},
  TURN_ID: {[NTR.PERFORMANCE_TURN_ID]: NTR.EMPTY},

  QUALIFICATION: {[NTR.PERFORMANCE_QUALIFICATION]: NTR.EMPTY},

  USER_FIELD: {[NTR.PERFORMANCE_USER_DATA]: NTR.EMPTY},
};

const ALL_PERFORMANCE_PARAMETERS = [
  PerformanceParameterOld.ARCHIVE,
  PerformanceParameterOld.AGE_CATEGORY,

  PerformanceParameterOld.CID,
  PerformanceParameterOld.CITY,
  PerformanceParameterOld.COMMENTS,
  PerformanceParameterOld.CONTACT,

  PerformanceParameterOld.DATE_CREATION,
  PerformanceParameterOld.DIRECTORS,
  PerformanceParameterOld.DOCUMENT_PRINTED,
  PerformanceParameterOld.DURATION,

  PerformanceParameterOld.ENTRY,

  PerformanceParameterOld.FILES,

  PerformanceParameterOld.GROUP,

  PerformanceParameterOld.LINK_ORIGINAL,

  PerformanceParameterOld.NOMINATION,

  PerformanceParameterOld.ORGANIZATION,
  PerformanceParameterOld.ORGANIZATION_CITY,
  PerformanceParameterOld.ORGANIZATION_CITY_PRINT,
  PerformanceParameterOld.ORGANIZATION_ID,

  PerformanceParameterOld.PAYMENT,
  PerformanceParameterOld.PHONOGRAM_DATE,
  PerformanceParameterOld.PHONOGRAM_DURATION,
  PerformanceParameterOld.PHONOGRAM_SIZE,
  PerformanceParameterOld.PHONOGRAM_START_WITH_POINT,
  PerformanceParameterOld.PHONOGRAM_STATUS,
  PerformanceParameterOld.PHONOGRAM_TITLE,
  PerformanceParameterOld.PLAYERS,
  PerformanceParameterOld.PLAYERS_AGE_RANGE,
  PerformanceParameterOld.PLAYERS_COUNT,
  PerformanceParameterOld.PLAYERS_NAME_COUNT,
  PerformanceParameterOld.PRIZE,
  PerformanceParameterOld.PRIZE_MANUAL,

  PerformanceParameterOld.RATING,
  PerformanceParameterOld.RATING_ALL,
  PerformanceParameterOld.RATING_SUM,
  PerformanceParameterOld.REGISTRATOR,

  PerformanceParameterOld.TEAM,
  PerformanceParameterOld.TEAM_CITY,
  PerformanceParameterOld.TEAM_CITY_PRINT,
  PerformanceParameterOld.TEAM_ID,
  PerformanceParameterOld.TIME_START,
  PerformanceParameterOld.TITLE,
  PerformanceParameterOld.TURN_ID,

  PerformanceParameterOld.USER_FIELD,

  PerformanceParameterOld.QUALIFICATION,
];

export const CONTESTADM_PERFORMANCE_PARAMETERS = [
  PerformanceParameterOld.TITLE,
  PerformanceParameterOld.CID,
  PerformanceParameterOld.PAYMENT,
  PerformanceParameterOld.NOMINATION,
  PerformanceParameterOld.AGE_CATEGORY,
  PerformanceParameterOld.QUALIFICATION,

  PerformanceParameterOld.ARCHIVE,
  PerformanceParameterOld.CITY,

  PerformanceParameterOld.TEAM,
  PerformanceParameterOld.TEAM_ID,
  PerformanceParameterOld.TEAM_CITY,

  PerformanceParameterOld.ORGANIZATION,
  PerformanceParameterOld.ORGANIZATION_ID,

  PerformanceParameterOld.DIRECTORS,
  PerformanceParameterOld.PLAYERS,
  PerformanceParameterOld.PLAYERS_COUNT,
  PerformanceParameterOld.CONTACT,

  PerformanceParameterOld.PHONOGRAM_TITLE,
  PerformanceParameterOld.PHONOGRAM_START_WITH_POINT,
  PerformanceParameterOld.PHONOGRAM_STATUS,
  PerformanceParameterOld.FILES,
];

export const EDIT_PERFORMANCE_PARAMETERS = [
  PerformanceParameterOld.TITLE,
  PerformanceParameterOld.CID,
  PerformanceParameterOld.ARCHIVE,

  PerformanceParameterOld.CITY,
  PerformanceParameterOld.TURN_ID,

  PerformanceParameterOld.NOMINATION,
  PerformanceParameterOld.AGE_CATEGORY,
  PerformanceParameterOld.QUALIFICATION,

  PerformanceParameterOld.GROUP,

  PerformanceParameterOld.TEAM,
  PerformanceParameterOld.TEAM_CITY,
  PerformanceParameterOld.TEAM_CITY_PRINT,
  PerformanceParameterOld.TEAM_ID,

  PerformanceParameterOld.ORGANIZATION,
  PerformanceParameterOld.ORGANIZATION_CITY,
  PerformanceParameterOld.ORGANIZATION_CITY_PRINT,
  PerformanceParameterOld.ORGANIZATION_ID,

  PerformanceParameterOld.DIRECTORS,
];


export const getDataPerformance = (performanceId, size, order, filter, parameters = ALL_PERFORMANCE_PARAMETERS) => {
  const request = {
    [NTR.GET_DATA]: {
      [NTR.PERFORMANCE_ID]: performanceId,
      [NTR.SIZE]: size,
      [NTR.ORDER_BY]: order,
      [NTR.FILTER]: filter,
      [NTR.DATA]: getDataRequest(parameters),
    }
  };
  return JSON.stringify(request);
};


export default class PerformanceData {
  static getAll({order, filter, parameters = ALL_PERFORMANCE_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PERFORMANCE_ID]: NTR.ZERO,
        [NTR.SIZE]: NTR.ALL,
        [NTR.ORDER_BY]: order,
        [NTR.FILTER]: filter,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static getOne({performanceId, filter, parameters = ALL_PERFORMANCE_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.SIZE]: NTR.ZERO,
        [NTR.FILTER]: filter,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }
}
