import {NTR} from '../../name-to-request.js';

export const getDataSearch = (text, area, data, filter) => {
  const request = {
    [NTR.GET_DATA]: {
      [NTR.SEARCH]: {
        [area]: {
          [NTR.TEXT]: text,
          [NTR.DATA]: data,
          [NTR.FILTER]: filter
        }
      }
    }
  };

  return JSON.stringify(request);
};

export default class SearchData {
  static get({area, text, parameter, filter}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.SEARCH]: {
          [area]: {
            [NTR.TEXT]: text,
            [NTR.DATA]: parameter,
            [NTR.FILTER]: filter
          }
        }
      }
    };
  }

  static getPerformance({area, text, filter}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.SEARCH]: {
          [area]: {
            [NTR.TEXT]: text,
            [NTR.FILTER]: filter
          }
        }
      }
    };
  }

  static getPeople({area, text, filter}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.SEARCH]: {
          [area]: {
            [NTR.TEXT]: text,
            [NTR.DATA]: `phone,email,city`,
            [NTR.FILTER]: filter
          }
        }
      }
    };
  }
}
