// import SetDataError from '../data/set/error.js';
import {getDataContest} from '../data/get/contest.js';
import {getDataNomination} from '../data/get/nomination.js';
import {getDataPeople} from '../data/get/people.js';
import {getDataPerformance} from '../data/get/performance.js';
import {getDataSearch} from '../data/get/search.js';
import ContestModel from '../data/contest/model.js';
import PeopleModel from '../model/people.js';
import PerformanceModel from '../model/performance.js';
import SearchModel from '../model/search.js';
import NominationModel from '../data/nomination/model.js';
import SetDataError from '../data/set/error.js';
import {FetchError} from '../error/fetch.js';

const Method = {
  GET: `GET`,
  POST: `POST`,
};

const TypeResponse = {
  OK: `ok`,
  ERROR: `error`,
  WARNING: `wargning`,
};

const checkStatus = (response, body) => {
  if (window.checkResponseController) {
    return window.checkResponseController.check(response, body);
  }

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  SetDataError.info(new API(), `${response.status}: ${response.statusText}; request: ${body}`);
  throw new Error(`${response.status}: ${response.statusText}`);
};

const checkResponses = (responses) => {
  if (responses.some((response) => response.result.toLowerCase() === TypeResponse.ERROR)) {
    throw responses;
  }

  return responses;
};

const checkResponse = (response) => {
  if (!response.result) {
    return response;
  }

  if (response.result.toLowerCase() === TypeResponse.OK) {
    return response;
  }

  if (response.result.toLowerCase() === TypeResponse.ERROR) {
    throw new Error(response.error);
  }

  if (response.result.toLowerCase() === TypeResponse.WARNING) {
    throw new Error(response.warning);
  }

  return response;
};

export default class API {
  // constructor() {
  //   if (typeof API.instance === `object`) {
  //     return API.instance;
  //   }
  //   API.instance = this;
  //   return API.instance;
  // }

  postData(data) {
    return this._loadData({
      method: Method.POST,
      body: JSON.stringify(data),
    });
  }

  postFormData(data, abortSignal) {
    return this._loadData({
      method: Method.POST,
      body: data,
      headers: new Headers(),
      signal: abortSignal,
    });
  }

  postFileChunk(blob, fileId, start, abortSignal = null) {
    return this._loadChunk({
      method: Method.POST,
      body: blob,
      headerFileId: fileId,
      headerStartChunk: String(start),
      signal: abortSignal,
    });
  }

  postFileDone(fileName, fileId) {
    return this._loadChunk({
      url: `../../ajax.php?action=done&name=${fileName}`,
      headerFileId: fileId,
    });
  }

  _loadData({url = `../../ajax.php`, method = Method.GET, body = null, credentials = `same-origin`, headers = new Headers({'Content-Type': `application/x-www-form-urlencoded`, 'Access-Control-Allow-Origin': `*`, 'Vary': `Origin`}), signal = null}) {
    return fetch(url, {method, body, credentials, headers, signal})
    .catch((err) => {
      const error = new FetchError({
        err,
      });
      throw error;
    });
  }

  _loadChunk({url = `../../ajax.php`, method = Method.GET, body = null, credentials = `same-origin`, headers = new Headers(), headerFileId = ``, headerStartChunk = ``, signal = null
  }) {
    headers.append(`Upload-Id`, headerFileId);
    if (headerStartChunk !== ``) {
      headers.append(`Portion-From`, headerStartChunk);
    }

    return fetch(url, {method, body, credentials, headers, signal})
    .then((response) => checkStatus(response, body))
    .catch((err) => {
      throw err;
    });
  }


  // все что ниже - перевести на то, что выше

  loadFileChunkPost(blob, fileId, start, abortSignal = null) {
    return this._loadChunk({
      method: Method.POST,
      body: blob,
      headerFileId: fileId,
      headerStartChunk: String(start),
      signal: abortSignal,
    })
    .then((response) => response.json())
    .then((response) => checkResponse(response));
  }

  loadDoneFileGet(fileName, fileId) {
    return this._loadChunk({
      url: `../../ajax.php?action=done&name=${fileName}`,
      headerFileId: fileId,
    })
    .then((response) => response.json());
  }

  loadPhonogram(formData) { // loadPhonogram(formData, abortController = null) {
    return this._load({
      method: Method.POST,
      body: formData,
      headers: new Headers(),
      // signal: abortController.signal,
    })
    .then((response) => response.json())
    .then((response) => checkResponse(response));
  }

  getData(request) {
    return this._load({
      method: Method.POST,
      body: JSON.stringify(request),
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json())
    .then((response) => checkResponse(response));
  }

  getSearch({text = ``, area = ``, data, filter}) {
    return this._load({
      method: Method.POST,
      body: getDataSearch(text, area, data, filter),
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json())
    .then(SearchModel.parseSearchDatum);
  }

  getContest({contestId, size = `0`, filter, parameters}) {
    return this._load({
      method: Method.POST,
      body: getDataContest(contestId, size, filter, parameters),
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json())
    .then(ContestModel.parseContest);
  }

  getContests({contestId = `0`, size = `all`, filter, parameters}) {
    return this._load({
      method: Method.POST,
      body: getDataContest(contestId, size, filter, parameters),
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json())
    .then(ContestModel.parseContests);
  }

  getPeople({peopleId = undefined, parameters}) {
    return this._load({
      method: Method.POST,
      body: getDataPeople(peopleId, parameters),
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json())
    .then((response) => PeopleModel.parsePeople(peopleId, response));
  }

  getPerformances({performanceId = `0`, size = `all`, order = undefined, filter = undefined, parameters = false}) {
    return this._load({
      method: Method.POST,
      body: getDataPerformance(performanceId, size, order, filter, parameters),
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json())
    .then(PerformanceModel.parsePerformances);
  }

  getPerformance({performanceId, size = `0`, order = undefined, filter = undefined, parameters = false}) {
    return this._load({
      method: Method.POST,
      body: getDataPerformance(performanceId, size, order, filter, parameters),
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json())
    .then(PerformanceModel.parsePerformance);
  }

  getNomination({nominationId = `0`, size = `all`, parameters}) {
    return this._load({
      method: Method.POST,
      body: getDataNomination(nominationId, size, parameters), // тело запроса в JSON
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json());
    // .then((response) => NominationModel.parseNomination(nominationId, response)); // разбор данных по человеку от сервера
  }

  getSubNomination({nomination, size = `all`, parameters}) {
    return this._load({
      method: Method.POST,
      body: getDataNomination(nomination.nominationId, size, parameters),
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json())
    .then((response) => NominationModel.parse(response, nomination));
  }

  setParameter(data) { // аналог getData
    return this._load({
      method: Method.POST,
      body: JSON.stringify(data),
      // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
    })
    .then((response) => response.json())
    .then((response) => checkResponse(response));
  }

  setParameters(datum) { // передается массив запросов
    return Promise.all(
        datum.map((data) => {
          return this._load({
            method: Method.POST,
            body: JSON.stringify(data),
            // headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
          })
          .then((response) => response.json());
        })
    )
    .then((responses) => checkResponses(responses));
  }

  downloadFile(link, abortController = null) {
    let fileName = ``;

    return this._loadChunk2({
      url: link,
      signal: abortController ? abortController.signal : null,
    })
    .then((response) => {
      const header = response.headers.get(`content-disposition`);
      if (!header) {
        return response.blob();
      }

      if (header.indexOf(`UTF-8`) > 0) {
        fileName = decodeURI(header.match(/filename\*=UTF-8''(.+)/)[1]);
      } else {
        fileName = header.match(/filename="(.+)"/)[1];
      }
      return response.blob();
    })
    .then((fileBlob) => {
      return {
        fileName,
        fileBlob,
      };
    });
  }

  downloadFileTest(link, abortController = null) {
    return this._loadChunk2({
      url: link,
      signal: abortController ? abortController.signal : null,
    })
    .then((response) => {
      return response.text();
    });
  }

  _loadChunk2({url = `../../ajax.php`, method = Method.GET, body = null, credentials = `same-origin`, headers = new Headers(), signal = null}) {
    return fetch(url, {method, body, credentials, headers, signal})
    .then((response) => checkStatus(response, body))
    .catch((err) => {
      throw err;
    });
  }

  _loadChunk({url = `../../ajax.php`, method = Method.GET, body = null, credentials = `same-origin`, headers = new Headers(), headerFileId = ``, headerStartChunk = ``, signal = null
  }) {
    headers.append(`Upload-Id`, headerFileId);
    if (headerStartChunk !== ``) {
      headers.append(`Portion-From`, headerStartChunk);
    }

    return fetch(url, {method, body, credentials, headers, signal})
    .then((response) => checkStatus(response, body))
    .catch((err) => {
      throw err;
    });
  }

  _load({url = `../../ajax.php`, method = Method.GET, body = null, credentials = `same-origin`, headers = new Headers({'Content-Type': `application/x-www-form-urlencoded`, 'Access-Control-Allow-Origin': `*`, 'Vary': `Origin`}), signal = null}) {
    return fetch(url, {method, body, credentials, headers, signal})
    .then((response) => checkStatus(response, body))
    .catch((err) => {
      throw err;
    });
  }
}
