import InstructionReminderPasswordComponent from '../component/instruction-reminder-password/instruction-reminder-password.js';
import Popup, {ContentType, PopupSize} from '../static/popup/popup.js';

export default class PromptLoginController {
  constructor() {
  }

  show() {
    const instructionComponent = new InstructionReminderPasswordComponent();

    Popup.showCustom({
      size: PopupSize.MEDIUM,
      contents: [
        {
          type: ContentType.TEXT,
          content: `Войдите на почту, указанную при регистрации. Найдите письмо с логином и паролем для доступа к сайту PrimeTime.today.`,
        },
        {
          type: ContentType.TEXT,
          content: `Если письма нет, попробуйте:`,
        },
        {
          type: ContentType.FRAGMENT,
          content: instructionComponent.getElement(),
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: `OK`,
              onClickHandler: () => {},
              isClose: true
            },
          ],
        }
      ]
    });
  }
}
