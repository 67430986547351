import Popup, {ContentType, PopupSize, PopupStyle} from "../../static/popup/popup";


export const showNotAuthEmailPopup = ({email, onReminderPasswordHandler}) => {
  Popup.showCustom({
    size: PopupSize.SMALL,
    style: PopupStyle.WARNING,
    title: `Внимание!`,
    contents: [
      {
        type: ContentType.TEXT,
        content: `Указанный Вами адрес электронной почты (<b>${email}</b>) уже используется.`,
      },
      {
        type: ContentType.TEXT,
        // content: `Пожалуйста, укажите другой адрес электронной почты или <button class="modal__button modal__button--asLinkBlue" type="button">Войдите в систему</button>.</p>`,
        content: `Пожалуйста, укажите другой адрес электронной почты или <a href="../">Войдите в систему</a>.</p>`,
      },
      {
        type: ContentType.BUTTONS,
        content: [
          {
            title: `Напомнить пароль`,
            onClickHandler: onReminderPasswordHandler,
            isClose: true,
          },
          {
            title: `Ввести другой e-mail`,
            isClose: true,
          },
        ],
      }
    ]
  });
};

