import AbstractComponent from '../abstract-component.js';

const createFooterPopContactTemplate = () => {
  return (
    `<section class="popup__fragment">
      <p class="pop__text--bold">Контакты:</p>
      <p class="pop__text">
        Фактический адрес: 454010, Челябинск, 9 Мая, дом 24<br>
        Электронная почта: info@PrimeTime.today <br>
        Телефоны: +7 951 479 79 79
      </p>
      <p class="pop__text--bold">Реквизиты:</p>
      <p class="pop__text">ИП Грачев Никита Михайлович <br>
        ИНН 744914632893 /ОГРНИП: 319745600065591, <br>
        Тел./факс: (351) 264-48-70
      </p>
    </section>`
  );
};

export default class FooterPopContactComponent extends AbstractComponent {
  getTemplate() {
    return createFooterPopContactTemplate();
  }
}
