import AbstractComponent from '../abstract-component.js';

const createFooterPopIframeTemplate = (link) => {
  return (
    `<section class="popup__fragment">
      <a class="footer__link footer__link--pop" href="${link}">Ссылка на источник</a>
      <iframe class="popup__iframe" src="${link}?embedded=true" frameborder="0" scrolling="auto"></iframe>
    </section>`
  );
};

export default class FooterPopIframeComponent extends AbstractComponent {
  constructor(link) {
    super();

    this._link = link;
  }

  getTemplate() {
    return createFooterPopIframeTemplate(this._link);
  }
}
