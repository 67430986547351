import ParsingData from "../static/parsing-data/parsing-data";
import {isEmptyArray} from "../utils/common";

const getCitiesSearched = (data, typeSearch = `other`) => {
  if (!data) {
    return [];
  }

  const keys = Object.keys(data);

  return keys
    .filter((key) => key !== `count`)
    .map((cityId) => {
      return {
        cityId,
        type: `город`,
        city: data[cityId].name || ``,
        googleId: data[cityId].google_city || ``,
        typeSearch,
      };
    });
};

export default class SearchModel {
  constructor(type, data) {
    this.type = type;
    this.searchData = data;
  }

  static parseSearchData(type, data) {
    return new SearchModel(type, data);
  }

  static parseSearchDatum(data) {
    const searchDatum = [];

    if (data.people) {
      const peoples = ParsingData.peopleFinded(data);
      const type = `peoples`;

      const searchData = SearchModel.parseSearchData(type, peoples);
      searchDatum.push(searchData);

      return {searchDatum, count: data.people.count};
    }

    if (data.city) {
      const cities = (isEmptyArray(data.city.other) && isEmptyArray(data.city.related)) ? [] : [].concat(getCitiesSearched(data.city.related, `related`), getCitiesSearched(data.city.other, `other`));

      const type = `cities`;

      const searchData = SearchModel.parseSearchData(type, cities);
      searchDatum.push(searchData);

      return {searchDatum, count: data.city.count};
    }

    return {searchDatum, count: `0`};
  }

  static parseSearchDatum2(data) {
    // const searchDatum = [];

    // if (data.performance) {
    const performances = ParsingData.performanceFinded(data);
    const type = `performances`;

    // const searchData = SearchModel.parseSearchData(type, performances);
    // searchDatum.push(searchData);
    // }

    return SearchModel.parseSearchData(type, performances);
  }

  static parseTeams(data) {
    const teams = ParsingData.performanceFinded(data);
    const type = `teams`;

    return SearchModel.parseSearchData(type, teams);
  }
}

// if (request.team) {
//   const teams = (request.team['0'] && request.team['0'] == 'не найдено') ? [] : getTeamsSearched(request.team);
//   const type = 'teams';

//   const searchData = SearchModel.parseSearchData(type, teams);
//   searchDatum.push(searchData);

//   return {searchDatum, count: request.team.count};
// }
