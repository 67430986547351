import {NTR} from "../../name-to-request";
import RequestController from "../../static/request/request";
import {getCookie} from "../../utils/common";
import ErrorParsing from "./parsing";
import ErrorRequest from "./request";

export default class ErrorData {
  constructor({api}) {
    this._api = api;
  }

  request({nameError, message, stack, request, response, errorType}) {
    const contestId = getCookie(`contest_id`) || null;

    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: ErrorRequest.setInfo,
        parameter: {nameError, message, stack, request, response, contestId, errorType},
        checkRequiredParameter: {},
        requiredResponseParameters: [NTR.ID],
        parseResponse: ErrorParsing.errorId,
        onResolveHandler: (errorId) => resolve(errorId),
        onRejectHandler: () => {},
        onRequestErrorHandler: () => {},
      }).send();
    });
  }
}
