import {NTR} from '../../name-to-request.js';
import {getDataRequest} from '../../utils/request.js';

export const PeopleParameter = {
  POSITION: {[NTR.PEOPLE_DIRECTOR]: NTR.EMPTY},
  PHONE: {[NTR.PEOPLE_PHONE_NUMBER]: NTR.EMPTY},
  EMAIL: {[NTR.PEOPLE_EMAIL]: NTR.EMPTY},
  NAME: {[NTR.PEOPLE_NAME]: NTR.EMPTY},
  DOB: {[NTR.PEOPLE_DOB]: NTR.EMPTY},
  AUTH: {[NTR.PEOPLE_PASS]: NTR.EMPTY},

  CARD_CONTEST: {[NTR.PEOPLE_CARD]: NTR.CONTEST},
  CARD_ALL: {[NTR.PEOPLE_CARD]: NTR.ALL},
};

const ALL_PEOPLE_PARAMETERS = [
  PeopleParameter.POSITION,
  PeopleParameter.PHONE,
  PeopleParameter.EMAIL,
  PeopleParameter.NAME,
  PeopleParameter.DOB,
  PeopleParameter.AUTH,
  PeopleParameter.CARD_CONTEST,
];


export const getDataPeople = (id, parameters = ALL_PEOPLE_PARAMETERS) => {
  const request = {
    [NTR.GET_DATA]: {
      [NTR.PEOPLE_ID]: id,
      [NTR.DATA]: getDataRequest(parameters),
    }
  };
  return JSON.stringify(request);
};
