import LangController from "../lang";
import LangSettingNewController from "../lang-setting_new";


export default class CommonLangController extends LangSettingNewController {
  constructor() {
    super();

    if (typeof CommonLangController.instance === `object`) {
      return CommonLangController.instance;
    }
    CommonLangController.instance = this;
    return CommonLangController.instance;
  }

  get ourActivityLink() {
    return LangController.getPropertyDefaultLang(`ourActivityGoogleDocs`) || ``;
  }

  get tariffLink() {
    return LangController.getPropertyDefaultLang(`tariffGoogleDocs`) || ``;
  }

  get privacyPolicyLink() {
    return LangController.getPropertyDefaultLang(`privacyPolicyGoogleDocs`) || ``;
  }

  get termsOfUseLink() {
    return LangController.getPropertyDefaultLang(`termsOfUseGoogleDocs`) || ``;
  }

  get speedTestLink() {
    return LangController.getPropertyDefaultLang(`speedTestLink`) || ``;
  }

  get fileFormats() {
    return LangController.getPropertyDefaultLang(`fileFormats`) || [];
  }

  get primetimeLogoLink() {
    return LangController.getPropertyDefaultLang(`primetimeLogoLink`) || ``;
  }

  get primetimeLogoMinOpacity() {
    return LangController.getPropertyDefaultLang(`primetimeLogoMinOpacity`) || 0.15;
  }


  set ourActivityLink(data) {
    LangController.setPropertyDefaultLang(`ourActivityGoogleDocs`, data);
  }

  set tariffLink(data) {
    LangController.setPropertyDefaultLang(`tariffGoogleDocs`, data);
  }

  set privacyPolicyLink(data) {
    LangController.setPropertyDefaultLang(`privacyPolicyGoogleDocs`, data);
  }

  set termsOfUseLink(data) {
    LangController.setPropertyDefaultLang(`termsOfUseGoogleDocs`, data);
  }

  set speedTestLink(data) {
    LangController.setPropertyDefaultLang(`speedTestLink`, data);
  }

  set fileFormats(data) {
    LangController.setPropertyDefaultLang(`fileFormats`, data);
  }

  set primetimeLogoLink(data) {
    LangController.setPropertyDefaultLang(`primetimeLogoLink`, data);
  }

  set primetimeLogoMinOpacity(data) {
    LangController.setPropertyDefaultLang(`primetimeLogoMinOpacity`, data);
  }
}
