import AbstractInputComponent from '../abstract-input.js';
import eyeSvg from '../../../../svg/eye.svg';
import noEyeSvg from '../../../../svg/no-eye.svg';

const createPasswordInputTemplate = (label, value, isRequired) => {
  return (
    `<div class="dropDownList__wrap" data-new="true" data-required="${isRequired}" data-validate="base">
      <label class="dropDownList__label">${label}</label>
      <input class="dropDownList__input" type="password" name="password" value="${value}" ${(isRequired) ? `required` : ``}>
      <div class="dropDownList__buttonWrap">
        <button class="master__btn master__btn--show" type="button" title="Показать пароль" style="right: 8px;">
          <svg class="master__btn-icon" aria-hidden="true" width="30" height="30">
            <use class="master__btn-icon--showPassword" href="${eyeSvg}"></use>
            <use class="master__btn-icon--hidePassword" href="${noEyeSvg}"></use>
          </svg>
        </button>
      </div>    
    </div>`
  );
};


export default class PasswordInputComponent extends AbstractInputComponent {
  getTemplate() {
    return createPasswordInputTemplate(this._label, this._value, this._isRequired);
  }

  _validate() {
    if (this.inputElement.validity.valid) {
      this.isValid = true;
      return;
    }

    this.isValid = false;
  }

  _onInputHandler() {
    this.value = this.value.trim();

  }

  setShowPasswordClickHandler() {
    const showPasswordButtonElement = this.getElement().querySelector(`.master__btn--show`);

    showPasswordButtonElement.addEventListener(`click`, () => {
      this.inputElement.type = (this.inputElement.type === `password`) ? `text` : `password`;
      showPasswordButtonElement.classList.toggle(`show`);
    });
  }

  setCheckCapsLockHandler() {
    document.addEventListener(`keydown`, this._onKeyPress);
  }

  _onKeyPress(evt) {
    const target = evt.target;
    if (target !== this.inputElement) {
      return;
    }

    const symbol = evt.key;

    if (symbol.toUpperCase() === symbol && symbol.toLowerCase() !== symbol && !evt.shiftKey) {
      this._renderDetailError(`Проверьте, возможно нажата клавиша CapsLock. Скорее всего пароль будет введен не правильно.`);
    } else {
      this._removeDetailError();
    }
  }
}
