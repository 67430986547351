export default class SoundmanModel {
  constructor(soundmanId, data) {
    this.soundmanId = soundmanId;

    this.lastname = data.lastname || ``;
    this.surname = data.surname || ``;
    this.middlename = data.middlename || ``;
  }

  toRAW() {
  }

  static newSoundman(soundmanId, data) {
    return new SoundmanModel(soundmanId, data);
  }

  static clone(soundmanId, data) {
    return new SoundmanModel(soundmanId, data.toRAW());
  }
}
