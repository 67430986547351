export const sortMailTemplates = (templates) => {
  const baseMailTemplates = templates
  .filter((template) => template.isBase);

  const orgMailTemplate = templates
    .filter((template) => !template.isBase && template.boundType === `org`);

  const contestMailTemplate = templates
    .filter((template) => !template.isBase && template.boundType === `contest`);

  return [].concat(contestMailTemplate, orgMailTemplate, baseMailTemplates);
};
