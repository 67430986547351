import Popup from "../static/popup/popup";

export class ServerError extends Error {
  constructor({message, api}) {
    super(message);

    this.name = `ServerError`;
    this._api = api;
  }

  show(errorId = ``) {
    Popup.showError({text: `Произошла ошибка. Код ошибки: ${errorId}`});
  }
}
