import AbstractComponent from '../abstract-component.js';

const createLoaderTemplate = (onAllPage) => {
  return (
    `<div class="modal__loader" ${onAllPage ? `style="position: fixed;"` : `style="position: absolute; z-index: 300;"`}>
      <svg class="page__spinner spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>`
  );
};

export default class LoaderComponent extends AbstractComponent {
  constructor(container) {
    super();

    this._onAllPage = !container;
  }

  getTemplate() {
    return createLoaderTemplate(this._onAllPage);
  }
}
