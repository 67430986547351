import {checkAccess} from '../../utils/common';
import PeopleParsing from './parsing';


export default class PeopleModel {
  constructor(peopleId, data) {
    this.peopleId = peopleId;

    this.name = PeopleParsing.peopleName(data);
    this.surname = PeopleParsing.peopleSurname(data);
    this.lastname = PeopleParsing.peopleLastname(data);
    this.middlename = PeopleParsing.peopleMiddlename(data);
    this.dob = PeopleParsing.peopleDob(data);
    this.phones = PeopleParsing.peoplePhone(data);
    this.emails = PeopleParsing.peopleEmail(data);
    this.positions = PeopleParsing.peopleDirector(data);
    this.cards = PeopleParsing.peopleCard(data);
    this.auth = PeopleParsing.peoplePass(data);
    this.aliases = PeopleParsing.peopleAlias(data);

    this.access = PeopleModel.getAccess(data);
  }

  toRAW() {
  }

  static newPeople(peopleId, data) {
    return new PeopleModel(peopleId, data);
  }

  static parsePeople(peopleId, data) {
    return PeopleModel.newPeople(peopleId, data);
  }

  static getAccess(data) {
    return {
      name: checkAccess(data.people_name),
      dob: checkAccess(data.people_dob),
      phones: checkAccess(data.people_phone_number),
      emails: checkAccess(data.people_email),
      positions: checkAccess(data.people_director),
      cards: checkAccess(data.people_card),
      auth: checkAccess(data.people_pass),
    };
  }

  static clone(peopleId, data) {
    return new PeopleModel(peopleId, data.toRAW());
  }

  // static getObjPeoples(peoples) {
  //   const objPeople = {};
  //   peoples.forEach((people) => {
  //     objPeople[people.peopleId] = people;
  //   });
  //   return objPeople;
  // }

  // static isSimilarName(currentPeople, newPeople) {
  //   return (currentPeople.surname === newPeople.surname
  //     && currentPeople.lastname === newPeople.lastname
  //     && currentPeople.middlename === newPeople.middlename);
  // }

  // static isSimilarDOB(currentPeople, newPeople) {
  //   return currentPeople.dob === newPeople.dob;
  // }

  // static getState(currentData, newData) {
  //   if (newData === ``) {
  //     return `delete`;
  //   }

  //   if (currentData === newData) {
  //     return `ok`;
  //   }

  //   if (currentData === `` && newData !== ``) {
  //     return `add`;
  //   }

  //   return `change`;
  // }


  // static getPositions(currentPeople, newPositions) {
  //   const count = Math.max(currentPeople.positions.length, newPositions.length);

  //   return new Array(count)
  //     .fill(``)
  //     .map((it, i) => {
  //       const directorId = currentPeople.positions[i] ? currentPeople.positions[i].directorId : ``;
  //       const currentPosition = currentPeople.positions[i] ? currentPeople.positions[i].title : ``;
  //       const newPosition = newPositions[i] ? newPositions[i] : ``;

  //       return {
  //         state: PeopleModel.getState(currentPosition, newPosition),
  //         old: currentPosition,
  //         title: newPosition,
  //         directorId,
  //       };
  //     })
  //     .filter((position) => !(position.title === `` && position.old === ``));
  // }

  // static setPositions(api, people, performanceId) {
  //   const positions = people.positions;

  //   return Promise.all(
  //       positions.filter((position) => position.state !== `ok`)
  //       .map((position) => {
  //         if (position.state === `add` && position.directorId === ``) {
  //           return SetDataPerformance.director(api, performanceId, people.peopleId, position.title);
  //         }
  //         if (position.state === `change` || (position.state === `add` && position.directorId !== ``)) {
  //           return SetDataPerformance.changePosition(api, position.directorId, position);
  //         }
  //         if (position.state === `delete`) {
  //           return SetDataPerformance.deletePosition(api, position.directorId);
  //         }
  //         return null;
  //       })
  //   );
  // }

  // static getPhones(currentPeople, newPhones) {
  //   const count = Math.max(currentPeople.phones.length, newPhones.length);

  //   return new Array(count)
  //     .fill(``)
  //     .map((it, i) => {
  //       const currentPhone = currentPeople.phones[i] ? currentPeople.phones[i].phone : ``;
  //       const newPhone = newPhones[i] ? newPhones[i] : ``;

  //       return {
  //         state: PeopleModel.getState(currentPhone, newPhone),
  //         oldPhone: currentPhone,
  //         phone: newPhone,
  //       };
  //     })
  //     .filter((phone) => !(phone.phone === `` && phone.oldPhone === ``));
  // }

  // static setPhones(api, people) {
  //   const phones = people.phones;

  //   return Promise.all(
  //       phones.filter((phone) => phone.state !== `ok`)
  //       .map((phone) => {
  //         if (phone.state === `add`) {
  //           return .addPhone(api, people.peopleId, phone.phone);
  //         }
  //         if (phone.state === `change`) {
  //           return SetDataPeople.changePhone(api, people.peopleId, phone);
  //         }
  //         if (phone.state === `delete`) {
  //           return SetDataPeople.deletePhone(api, people.peopleId, phone);
  //         }
  //         return null;
  //       })
  //   );
  // }

  // static getEmails(currentPeople, newEmails, newEmailIds = []) {
  //   const count = Math.max(currentPeople.emails.length, newEmails.length);

  //   return new Array(count)
  //     .fill(``)
  //     .map((it, i) => {
  //       const currentEmail = currentPeople.emails[i] ? currentPeople.emails[i].mail : ``;
  //       const newEmail = newEmails[i] ? newEmails[i] : ``;
  //       const newEmailId = newEmailIds[i] ? newEmailIds[i] : ``;

  //       return {
  //         state: PeopleModel.getState(currentEmail, newEmail),
  //         oldMail: currentEmail,
  //         mail: newEmail,
  //         mailId: newEmailId,
  //       };
  //     })
  //     .filter((email) => !(email.mail === `` && email.oldMail === ``));
  // }

  // static setEmails(api, people) {
  //   const emails = people.emails;

  //   return Promise.all(
  //       emails.filter((email) => email.state !== `ok`)
  //       .map((email) => {
  //         if (email.state === `add`) {
  //           return SetDataPeople.addEmail(api, people.peopleId, email.mail);
  //         }
  //         if (email.state === `change`) {
  //           return SetDataPeople.changeEmail(api, people.peopleId, email);
  //         }
  //         if (email.state === `delete`) {
  //           return SetDataPeople.deleteEmail(api, people.peopleId, email);
  //         }
  //         return null;
  //       })
  //   );
  // }
}
