import {DOMAIN, Service, ServiceEnToServiceRus} from '../const.js';
import {isDomain} from '../utils/common.js';
import {render, RenderPosition} from '../utils/render.js';
import SectionComponent from '../component/base/section.js';
import ParagraphComponent from '../component/base/paragraph.js';
import UlComponent from '../component/base/ul.js';
import LiComponent from '../component/base/li.js';
import ButtonController from './button.js';
import FooterLogoComponent from '../component/footer-logo/footer-logo.js';
import ActivityServiceController from './service/activity.js';
import ContactServiceController from './service/contact.js';
import TariffServiceController from './service/tariff.js';
import PaymentServiceController from './service/payment.js';
import DataTransferServiceController from './service/data-transfer.js';
import PrivacyPolicyServiceController from './service/privacy-policy.js';
import TermsOfUseServiceController from './service/terms-of-use.js';
import CommonLangController from './lang-setting/default.js';

const ServiceTitle = {
  ABOUT: `О проекте`,
  FINANCE: `Финансы`,
  TEHNOLOGY: `Технологии`,
  SECURITY: `Безопасность`,
};

export default class FooterController {
  constructor({container}) {
    this._container = container;

    this._ourActivityLink = new CommonLangController().ourActivityLink;
    this._tariffLink = new CommonLangController().tariffLink;
    this._privacyPolicyLink = new CommonLangController().privacyPolicyLink;
    this._termsOfUseLink = new CommonLangController().termsOfUseLink;

    this._onOpenServiceHandler = this._onOpenServiceHandler.bind(this);
  }

  render() {
    this._renderSticky();
    this._renderContent();
  }

  _renderSticky() {
    if (isDomain(DOMAIN.PT) || isDomain(DOMAIN.PT_RES)) {
      return;
    }

    const stickyWrap = new SectionComponent(`footer__sticky`);
    render(this._container, stickyWrap.getElement(), RenderPosition.BEFOREEND);

    const text = new ParagraphComponent({text: `Работает по технологии PrimeTime.today`});
    render(stickyWrap.getElement(), text.getElement(), RenderPosition.BEFOREEND);
  }

  _renderContent() {
    const contentWrap = new SectionComponent(`footer__wrap`);
    render(this._container, contentWrap.getElement(), RenderPosition.BEFOREEND);

    this._renderLogo(contentWrap.getElement());
    this._renderServices(contentWrap.getElement());
  }

  _renderLogo(container) {
    const component = new FooterLogoComponent();
    render(container, component.getElement(), RenderPosition.BEFOREEND);
  }

  _renderServices(container) {
    const ulComponent = new UlComponent(`footer__list`);
    render(container, ulComponent.getElement(), RenderPosition.BEFOREEND);

    this._renderAbout(ulComponent.getElement());
    this._renderFinance(ulComponent.getElement());
    this._renderTehnology(ulComponent.getElement());
    this._renderSecurity(ulComponent.getElement());
  }

  _renderAbout(container) {
    const liComponent = this._renderListItem(container, ServiceTitle.ABOUT);
    const subListComponent = this._renderSubList(liComponent.getElement());

    this._renderSubListItem(subListComponent.getElement(), Service.ACTIVITY);
    this._renderSubListItem(subListComponent.getElement(), Service.CONTACT);
  }

  _renderFinance(container) {
    const liComponent = this._renderListItem(container, ServiceTitle.FINANCE);
    const subListComponent = this._renderSubList(liComponent.getElement());

    this._renderSubListItem(subListComponent.getElement(), Service.TARIFF);
    this._renderSubListItem(subListComponent.getElement(), Service.PAYMENT);
  }

  _renderTehnology(container) {
    const liComponent = this._renderListItem(container, ServiceTitle.TEHNOLOGY);
    const subListComponent = this._renderSubList(liComponent.getElement());

    this._renderSubListItem(subListComponent.getElement(), Service.DATA_TRANSFER);
  }

  _renderSecurity(container) {
    const liComponent = this._renderListItem(container, ServiceTitle.SECURITY);
    const subListComponent = this._renderSubList(liComponent.getElement());

    this._renderSubListItem(subListComponent.getElement(), Service.PRIVACY_POLICY);
    this._renderSubListItem(subListComponent.getElement(), Service.TERMS_OF_USE);
  }

  _renderListItem(container, title) {
    const liComponent = new LiComponent(`footer__listItem`, title);
    render(container, liComponent.getElement(), RenderPosition.BEFOREEND);
    return liComponent;
  }

  _renderSubList(container) {
    const subListComponent = new UlComponent(`footer__subList`);
    render(container, subListComponent.getElement(), RenderPosition.BEFOREEND);
    return subListComponent;
  }

  _renderSubListItem(container, service) {
    const liComponent = new LiComponent(`footer__subListItem`);
    this._renderButton(liComponent.getElement(), service);

    render(container, liComponent.getElement(), RenderPosition.BEFOREEND);
  }

  _renderButton(container, service) {
    const button = new ButtonController({
      container,
      className: `footer__subButton`,
      name: service,
      title: ServiceEnToServiceRus[service],
      onClickHandler: this._onOpenServiceHandler,
    });

    button.render();
  }

  _showService(service) {
    switch (service) {
      case Service.ACTIVITY:
        const activityDocumentController = new ActivityServiceController(this._ourActivityLink);
        activityDocumentController.render();
        return ``;
      case Service.CONTACT:
        const contactDocumentController = new ContactServiceController();
        contactDocumentController.render();
        return ``;
      case Service.TARIFF:
        const tariffDocumentController = new TariffServiceController(this._tariffLink);
        tariffDocumentController.render();
        return ``;
      case Service.PAYMENT:
        const paymentDocumentController = new PaymentServiceController();
        paymentDocumentController.render();
        return ``;
      case Service.DATA_TRANSFER:
        const dataTransferDocumentController = new DataTransferServiceController();
        dataTransferDocumentController.render();
        return ``;
      case Service.PRIVACY_POLICY:
        const privacyPolicyDocumentController = new PrivacyPolicyServiceController(this._privacyPolicyLink);
        privacyPolicyDocumentController.render();
        return ``;
      case Service.TERMS_OF_USE:
        const termsOfUseDocumentController = new TermsOfUseServiceController(this._termsOfUseLink);
        termsOfUseDocumentController.render();
        return ``;
      default:
        return null;
    }
  }

  _onOpenServiceHandler(service) {
    this._showService(service);
  }
}
