import Popup from "../static/popup/popup";

export class ResponseStatusError extends Error {
  constructor({message}) {
    super(message);

    this.name = `ResponseStatusError`;
  }

  show(errorId, textError = ``) {
    Popup.showError({text: `Сервер вернул ответ: ${textError}. <br>Код ошибки: ${errorId}`});
  }
}
