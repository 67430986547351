import {BlockedError} from "../../error/blocked";
import {NoAccessError} from "../../error/no-access";
import {RequestError} from "../../error/request";
import {ServerError} from "../../error/server";
import {WarningError} from "../../error/warning";
import {NTR} from "../../name-to-request";

const getParameterError = (response) => {
  const keys = Object.keys(response);

  let parameter = {};

  keys.filter((key) => key !== `error` && key !== `id` && key !== `result`)
  .forEach((key) => {
    parameter[key] = response[key];
  });

  return parameter;
};


export default class CheckResponse {
  constructor({response, request}) {
    this._response = response;
    this._request = request;
  }

  static check({response, checkedRequiredParameter = []}) {
    // проверка на пустоту ответа
    if (typeof response === `undefined`) {
      throw new ServerError({message: `response is undefined`});
    }

    if (response === null) {
      throw new ServerError({message: `response is null`});
    }

    // if (isEmptyObject(response)) {
    //   throw new ServerError({message: `response is empty`});
    // }

    // проверка на доступ совершения операции
    if (response.result
      && response.result.toLowerCase() === `error`
      && (response.error === NTR.NO_ACCESS || response.error === `no access`)) {

      throw new NoAccessError({message: response.error, id: response.id});
    }

    // проверка на оплаченность совершения операции
    if (response.result
      && response.result.toLowerCase() === `error`
      && response.error === `pay`) {

      throw new BlockedError({message: response.error, id: response.id});
    }

    // проверка на другой ответ с результатом error
    if (response.result && response.result.toLowerCase() === `error`) {
      throw new RequestError({message: response.error, id: response.id, parameter: getParameterError(response)});
    }

    if (response.result && response.result.toLowerCase() === `warning`) {
      throw new WarningError({message: response.warning, id: response.id, parameter: getParameterError(response)});
    }

    checkedRequiredParameter.forEach((parameter) => {
      if (typeof response[parameter] === `undefined`) {
        throw new ServerError({message: `${parameter} is undefined`});
      }

      if (!response[parameter] && response[parameter] !== null) {
        throw new ServerError({message: `${parameter} is empty`});
      }
    });
  }
}

// ошибка query  not found
// contest_id not set
