import AbstractComponent from '../abstract-component.js';

const createFooterPopPaymentTemplate = () => {
  return (
    `<section class="popup__fragment">
      <p class="pop__text">Банковской картой.</p>
      <p class="pop__text">Для выбора оплаты услуг с помощью банковской карты на соответствующей странице необходимо нажать кнопку <span class="pop__text--bold">«Оплата заказа банковской картой»</span>. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платежных систем:</p>
      <ul>
      <li>МИР
          <img src="sienge/img/mir-logo-h14px.svg" alt="Платежная система МИР" width="30">
      </li>
      <li>VISA International
          <img src="sienge/img/visa-logo.svg" alt="Платежная система Visa" width="30">
      </li>
      <li>Mastercard Worldwide
          <img src="sienge/img/mastercard-seeklogo.com.svg" alt="Платежная система MasterCard" width="30">
      </li>
      <li>JCB
          <img src="sienge/img/jcb_emblem_logo.svg" alt="Платежная система JCB" width="30">
      </li>
      </ul>
    </section>`
  );
};

export default class FooterPopPaymentComponent extends AbstractComponent {
  getTemplate() {
    return createFooterPopPaymentTemplate();
  }
}
