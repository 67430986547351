import {DocumentsType} from "../const/document";

export const sortDocuments = (documents) => {
  const baseGoogleDocuments = documents
  .filter((document) => document.isBase && document.type === DocumentsType.GOOGLE);

  const noBaseDocuments = documents
    .filter((document) => !document.isBase);

  const baseNoGoogleDocuments = documents
    .filter((document) => document.isBase && document.type !== DocumentsType.GOOGLE);

  return [].concat(baseGoogleDocuments, noBaseDocuments, baseNoGoogleDocuments);
};

export const getIsBaseDocument = (title) => {
  const regex = /[\|~]?Базовый/;
  return regex.test(title);
};

export const getTypeDocument = (title) => {
  if (title.indexOf(`|`) === 0) {
    return DocumentsType.GOOGLE;
  }

  if (title.indexOf(`~`) === 0) {
    return DocumentsType.POPUP;
  }

  if (title.indexOf(`&`) === 0) {
    return DocumentsType.FRONT;
  }

  return DocumentsType.NONE;
};
