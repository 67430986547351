/* eslint-disable no-undef */
import Popup from "../static/popup/popup";
import {clickLink, getBlob, isTT} from "../utils/common";
import FileLoadController from "./load/file";

// import {getConstValue} from "./common";


// const customLang = getConstValue(`customLang`);
// const customOrgLang = getConstValue(`customOrgLang`);
// const defaultLang = getConstValue(`defaultLang`);


export default class LangController {
  static getPropertyDefaultLang(property) {
    return (typeof defaultLang !== `undefined` && defaultLang[property]) || null;
  }

  static setPropertyDefaultLang(name, value) {
    if (!defaultLang) {
      defaultLang = {};
    }

    defaultLang[name] = value;
  }

  static getPropertyLang(property) {
    return (typeof customLang !== `undefined` && customLang[property]) ? customLang[property] : null;

    // const value = (typeof customLang !== `undefined` && customLang[property]) || undefined;
    // return value ? value : (typeof customOrgLang !== `undefined` && customOrgLang[property]) || null;
  }

  static getSettingNewLang(lang) {
    return (typeof lang !== `undefined`) ? lang : null;
  }

  static getPropertyLango(property) {
    return (typeof customOrgLang !== `undefined` && customOrgLang[property]) ? customOrgLang[property] : null;
  }

  static getGlobalSettingPropertyLang(property) {
    const value = (typeof customLang !== `undefined` && customLang.globalSetting && customLang.globalSetting[property]) || undefined;

    return value ? value : (typeof customOrgLang !== `undefined` && customOrgLang.globalSetting && customOrgLang.globalSetting[property]) || null;
  }

  static getFunctionLang(method) {
    const valueContest = (typeof customLang !== `undefined` && customLang[method]);
    let value = valueContest ? valueContest : (typeof customOrgLang !== `undefined` && customOrgLang[method]) || null;

    if (typeof value === `string` && value.startsWith(`/Function(`) && value.endsWith(`)/`)) {
      value = value.substring(10, value.length - 2);
      const func = new Function(value);
      return func;
    }

    return () => {};
  }

  static getFunctionTextLang(property) {
    const value = (typeof customLang !== `undefined` && customLang[property]) || undefined;
    const functionText = value ? value : (typeof customOrgLang !== `undefined` && customOrgLang[property]) || null;

    return functionText ? functionText.substring(10, functionText.length - 2) : ``;
  }

  static setPropertyLang(property, value) {
    if (typeof customLang === `undefined`) {
      LangController.update();
    }

    customLang[property] = value;
  }

  static setPropertyLango(property, value) {
    if (typeof customOrgLang === `undefined`) {
      window.customOrgLang = {};
    }

    customOrgLang[property] = value;
  }

  static setFunctionLang(property, value) {
    if (typeof customLang === `undefined`) {
      LangController.update();
    }

    value = `/Function(${value})/`;

    customLang[property] = value;
  }

  static removePropertyLang(property) {
    if (typeof customLang === `undefined`) {
      return;
    }

    delete customLang[property];
  }

  static saveDefaultLang({api, container, onSavedHandler}) {
    if (typeof defaultLang === `undefined`) {
      defaultLang = {};
    }

    const defaultLangJSON = JSON.stringify(defaultLang);

    const text = `const defaultLang = ${defaultLangJSON}`;

    const fileBlob = getBlob(text);
    fileBlob.name = `default.js`;

    const loadController = new FileLoadController({
      api,
      container,
      onLoadedFile: onSavedHandler
    });
    loadController.load([fileBlob]);
  }

  static save(api, container, onSavedHandler) {
    if (typeof customLang === `undefined`) {
      LangController.update();
    }

    const customLangJSON = JSON.stringify(customLang, (key, value) => {
      if (typeof value === `function`) {
        return `/Function(` + value.toString() + `)/`;
      }

      return value;
    }, 2);


    const text = `const customLang = ${customLangJSON}`;

    const fileBlob = getBlob(text);
    fileBlob.name = `lang.js`;

    const loadController = new FileLoadController({
      api,
      container,
      onLoadedFile: onSavedHandler
    });
    loadController.load([fileBlob]);
  }

  static saveNewLang(api, container, nameSetting, nameLang, setting, onResolveHandler) {

    const json = JSON.stringify(setting, function (key, value) {
      if (key === `controller`) {
        return undefined;
      }
      return value;
    });

    const text = `window.${nameSetting} = ${json}`;

    const fileBlob = getBlob(text);
    fileBlob.name = `${nameLang}.js`;

    const loadController = new FileLoadController({
      api,
      container,
      onLoadedFile: () => {
        Popup.showNotification({text: `Сохранено!`});
        onResolveHandler();
      }
    });
    loadController.load([fileBlob]);
  }

  static removeNewLang(api, container, nameLang, onResolveHandler) {
    // const json = JSON.stringify(setting);
    const text = ``;

    const fileBlob = getBlob(text);
    fileBlob.name = `${nameLang}.js`;

    const loadController = new FileLoadController({
      api,
      container,
      onLoadedFile: () => {
        Popup.showNotification({text: `Сохранено!`});
        onResolveHandler();
      }
    });
    loadController.load([fileBlob]);
  }

  static saveLango(api, container, onSavedHandler) {
    if (typeof customOrgLang === `undefined`) {
      window.customOrgLang = {};
    }

    if (isTT()) {
      customOrgLang.globalSetting.LOGO = `test`;
    }

    const customOrgLangJSON = JSON.stringify(customOrgLang);

    const text = `const customOrgLang = ${customOrgLangJSON}`;

    const fileBlob = getBlob(text);
    fileBlob.name = `lango.js`;

    const loadController = new FileLoadController({
      api,
      container,
      onLoadedFile: onSavedHandler
    });
    loadController.load([fileBlob]);
  }

  static saveAs(property, contestId, title = `template`) {
    const customLangJSON = JSON.stringify(customLang[property], null, `  `);

    const text = `${customLangJSON}`;
    const fileBlob = getBlob(text);
    const date = new Date();
    const dateLoad = `${date.toLocaleDateString()} ${date.toLocaleTimeString().slice(0, 5)}`.replace(`:`, `.`);

    const link = URL.createObjectURL(fileBlob);

    clickLink(link, `${title}-${contestId} ${dateLoad}.pttt`);

    URL.revokeObjectURL(link);
  }

  static saveAsNew(setting, contestId, title = `template`) {
    const customLangJSON = JSON.stringify(setting, null, `  `);

    const text = `${customLangJSON}`;
    const fileBlob = getBlob(text);
    const date = new Date();
    const dateLoad = `${date.toLocaleDateString()} ${date.toLocaleTimeString().slice(0, 5)}`.replace(`:`, `.`);

    const link = URL.createObjectURL(fileBlob);

    clickLink(link, `${title}-${contestId} ${dateLoad}.pttt`);

    URL.revokeObjectURL(link);
  }

  static update() {
    window.customLang = {};
  }
}
