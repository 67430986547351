import {NTR} from "../../name-to-request";
import {md5} from "../../utils/md5";

export default class LoginRequest {
  static logon({login, password}) {
    return {
      [NTR.SET_LOGON]: {
        [NTR.EMAIL]: login,
        [NTR.PASSWORD]: md5(password),
      }
    };
  }

  static logout() {
    return {
      [NTR.SET_LOGOFF]: NTR.EMPTY
    };
  }
}
