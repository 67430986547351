import EmailInputComponent from "../component/input/email-input/email-input";
import PasswordInputComponent from "../component/input/password-input/password-input";
import LoaderController from "./loader";
import ReminderPasswordController from "./reminder-password";
import ButtonComponent from "../component/base/button";
import PromptLoginController from "./prompt-login";
import {KEY_NAME} from "../const";
import Popup, {ContentType, PopupSize} from "../static/popup/popup";
import TextInputComponent from "../component/input/text-input/text-input";
import {openPage} from "../utils/common";
import LoginData from "../data/login/login";
import PeopleData from "../data/people/people";
import {showNotAuthEmailPopup} from "./popup-custom/not-auth-email";


export default class LoginController {
  constructor({api}) {
    this._api = api;
    // this._onAuthorization = onAuthorization;
    // this._onCloseLoginPopupHandler = onCloseLoginPopupHandler;
    this._reminderPasswordController = null;
    this._loader = new LoaderController();
    this._container = null;

    this._login = ``;
    this._loginLink = ``;
    this._password = ``;

    this._loginData = new LoginData({api});

    this._onAuthHandlers = [];


    this._popupController = null;
    this._container = null;
    this._errorComponent = null;
    this._promptButtonClassName = `modal__button modal__button--asLink`;

    this._onPromptButtonClickButton = this._onPromptButtonClickButton.bind(this);
    this._onLoginInputHandler = this._onLoginInputHandler.bind(this);
    this._onPasswordInputHandler = this._onPasswordInputHandler.bind(this);
    this._onLoginLinkButtonClickHandler = this._onLoginLinkButtonClickHandler.bind(this);
    this._onLogin = this._onLogin.bind(this);
    this._onLoginLink = this._onLoginLink.bind(this);
    this._onValidateLoginLinkHandler = this._onValidateLoginLinkHandler.bind(this);
    this._onRemindPassword = this._onRemindPassword.bind(this);
    this._onSentPasswordHandler = this._onSentPasswordHandler.bind(this);
    this._onCloseReminderPasswordPopupHandler = this._onCloseReminderPasswordPopupHandler.bind(this);
    this._notAutnEmailHandler = this._notAutnEmailHandler.bind(this);

    this._onEnterPress = this._onEnterPress.bind(this);

    this._mode = `login`;
  }

  render() {}

  setAutnHandler(handler) {
    this._onAuthHandlers.push(handler);
  }

  setAuthEmail(peopleId, email) {
    return new PeopleData({api: this._api, isShowError: false}).setAuthEmail({
      peopleId,
      email,
      notAutnEmailHandler: () => this._notAutnEmailHandler(email),
    })
    .then(() => this._onSuccessHandler());
  }

  _notAutnEmailHandler(email) {
    showNotAuthEmailPopup({
      email,
      onReminderPasswordHandler: this._onRemindPassword,
    });
  }

  showPopup(email) {
    showNotAuthEmailPopup({
      email,
      onReminderPasswordHandler: this._onRemindPassword,
    });
  }


  // onSuccessAuth() {
  //   this._onSuccessHandler();
  // }

  _getLoginInputComponent() {
    const inputComponent = new EmailInputComponent({
      label: `E-mail`,
      value: this._login,
      isRequired: true,
    });

    inputComponent.setInputHandler(this._onLoginInputHandler);
    inputComponent.setChangeHandler(this._onLoginInputHandler);

    return inputComponent;
  }

  _getLoginLinkInputComponent() {
    const inputComponent = new TextInputComponent({
      // label: `Короткая ссылка`,
      value: this._loginLink,
      isRequired: true,
      customValidate: this._onValidateLoginLinkHandler,
      errorValidateText: `Неверно задана ссылка`,
    });

    return inputComponent;
  }

  _getLoginLink(value) {
    const minCountTextSymbol = 4;
    const maxCountTextSymbol = 5;

    let link = value.replace(/\s+/g, ``).trim().slice(-1 * (maxCountTextSymbol + 1));

    if (link.lastIndexOf(`/`) >= 0) {
      link = link.slice(-1 * (link.length - link.lastIndexOf(`/`) - 1));
    }

    if ((link.length >= minCountTextSymbol && link.length <= maxCountTextSymbol) && link.indexOf(`-`) === -1) {
      return link;
    }

    if (link.indexOf(`-`) === 0 && (link.length >= minCountTextSymbol && link.length <= maxCountTextSymbol + 1)) {
      return link.slice(1);
    }

    return link;
  }

  _onValidateLoginLinkHandler(value) {
    const minCountTextSymbol = 4;
    const maxCountTextSymbol = 5;

    const link = this._getLoginLink(value);
    this._loginLink = link;

    return link.length >= minCountTextSymbol && link.length <= maxCountTextSymbol;
  }

  _getPasswordInputComponent() {
    const inputComponent = new PasswordInputComponent({
      label: `Пароль`,
      isRequired: true,
    });

    inputComponent.setInputHandler(this._onPasswordInputHandler);
    inputComponent.setChangeHandler(this._onPasswordInputHandler);
    inputComponent.setShowPasswordClickHandler();
    inputComponent.setCheckCapsLockHandler();

    return inputComponent;
  }

  _getReminderPasswordButton() {
    const button = new ButtonComponent({
      className: `form-registration__buttonLink`,
      title: `Восстановить пароль`,
    });
    button.setClickHandler(this._onRemindPassword);

    return button;
  }

  _getPromptButton() {
    const button = new ButtonComponent({
      className: this._promptButtonClassName,
      title: `Не могу войти`,
    });
    button.setClickHandler(this._onPromptButtonClickButton);

    return button;
  }

  _getLoginButton() {
    const button = new ButtonComponent({
      className: `modal__button`,
      title: `Войти`,
    });
    button.setClickHandler(this._onLogin);

    return button;
  }

  _getLoginLinkButton() {
    const button = new ButtonComponent({
      className: `modal__button modal__button--grey`,
      title: `Войти по ссылке`,
    });
    button.setClickHandler(this._onLoginLinkButtonClickHandler);

    return button;
  }

  _onPromptButtonClickButton() {
    const promptLoginController = new PromptLoginController();
    promptLoginController.show();
  }

  _onLoginInputHandler(value) {
    this._login = value;
  }

  _onPasswordInputHandler(value) {
    this._password = value;
  }

  _onRemindPassword() {
    this._mode = `reminder`;

    if (this._reminderPasswordController) {
      return;
    }

    this._reminderPasswordController = new ReminderPasswordController({
      api: this._api,
      onSentPasswordHandler: this._onSentPasswordHandler,
      onClosePopupHandler: this._onCloseReminderPasswordPopupHandler,
    });
    this._reminderPasswordController.show();
  }

  _getValid() {
    return !!this._login && !!this._password;
  }

  _showError() {}

  _onLogin() {
    const isValid = this._getValid();

    if (!isValid) {
      this._showError();
      return;
    }

    this._loginData.login(this._login, this._password, () => this._onSuccessHandler(), (error) => this._onRequestErrorHandler(error));
  }

  _onRequestErrorHandler(error) {
    const text = this._getTextError(error.message);
    this._showRequestError(text);
  }

  _onLoginLinkButtonClickHandler() {
    this._loginLink = ``;

    Popup.showCustom({
      size: PopupSize.SMALL,
      title: `Введите короткую ссылку`,
      // mode: ValidateMode.ACTIVE,
      contents: [
        {
          type: ContentType.INPUT,
          content: this._getLoginLinkInputComponent(),
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: `Отмена`,
              isClose: true,
            },
            {
              title: `Войти`,
              onClickHandler: this._onLoginLink,
              isRequired: true,
            }
          ],
        }
      ]
    });
  }

  _onLoginLink() {
    const link = `/-${this._loginLink}`;
    openPage(link, `_self`);
  }

  _onSentPasswordHandler() {}

  _onCloseReminderPasswordPopupHandler() {
    this._mode = `login`;
    this._reminderPasswordController = null;
  }

  _onSuccessHandler() {
    this._onAuthHandlers.forEach((handler) => handler(true));

    document.removeEventListener(`keydown`, this._onEnterPress);
  }

  _onEnterPress(evt) {
    if (this._mode === `login` && evt.key === KEY_NAME.ENTER) {
      this._onLogin();
    }
  }

  _getTextError(error) {
    switch (error.toLowerCase()) {
      case `e-mail incorrect`:
        return `Указан некорректный e-mail`;
      case `e-mail not found`:
        return `E-mail не найден`;
      case `wrong password`:
        return `Неверный пароль`;
      default:
        return `Не удалось авторизоваться`;
    }
  }

  _showRequestError() {}
}
