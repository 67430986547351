import LoginController from "./login";
import {remove, render, RenderPosition, replace} from "../utils/render";
import ParagraphComponent from "../component/base/paragraph";
import DivComponent from "../component/base/div";

export default class LoginIndexPageController extends LoginController {
  render(container) {
    this._container = container;
    this._promptButtonClassName = `form-registration__buttonLink`;

    const textComponent = new ParagraphComponent({
      className: `main__loginInfo`,
      text: `Вход в систему`,
    });
    render(container, textComponent.getElement(), RenderPosition.BEFOREEND);

    const loginInputComponent = this._getLoginInputComponent();
    render(container, loginInputComponent.getElement(), RenderPosition.BEFOREEND);

    const passwordInputComponent = this._getPasswordInputComponent();
    render(container, passwordInputComponent.getElement(), RenderPosition.BEFOREEND);

    const reminderPaswordButton = this._getReminderPasswordButton();
    render(container, reminderPaswordButton.getElement(), RenderPosition.BEFOREEND);

    const promptButton = this._getPromptButton();
    render(container, promptButton.getElement(), RenderPosition.BEFOREEND);

    const wrapButtonComponent = new DivComponent(`popup__wrapButton`);

    const loginButton = this._getLoginButton();
    render(wrapButtonComponent.getElement(), loginButton.getElement(), RenderPosition.BEFOREEND);

    const loginLinkButton = this._getLoginLinkButton();
    render(wrapButtonComponent.getElement(), loginLinkButton.getElement(), RenderPosition.BEFOREEND);

    render(container, wrapButtonComponent.getElement(), RenderPosition.BEFOREEND);

    document.addEventListener(`keydown`, this._onEnterPress);
  }

  _showError() {
    if (!this._login && !this._password) {
      this._renderError(`Введите E-mail и Пароль`);
    }

    if (!!this._login && !this._password) {
      this._renderError(`Введите Пароль`);
    }

    if (!this._login && !!this._password) {
      this._renderError(`Введите E-mail`);
    }
  }

  _onSuccessHandler() {
    if (this._errorComponent) {
      remove(this._errorComponent);
    }
    super._onSuccessHandler();
  }

  _showRequestError(text) {
    this._renderError(text);
  }

  _renderError(text) {
    const oldErrorComponent = this._errorComponent;

    this._errorComponent = new ParagraphComponent({
      className: `text text--bold text--error`,
      text,
    });

    this._errorComponent.getElement().style.marginTop = `20px`;

    if (oldErrorComponent) {
      replace(this._errorComponent, oldErrorComponent);
    } else {
      render(this._container, this._errorComponent.getElement(), RenderPosition.BEFOREEND);
    }
  }
}
