import FooterPopContactComponent from "../../component/footer-pop-contact/footer-pop-contact";
import {Service} from "../../const";
import {PopupSize} from "../../static/popup/popup";
import ServiceController from "../service";

export default class ContactServiceController extends ServiceController {
  constructor() {
    super();

    this._service = Service.CONTACT;
    this._popupSize = PopupSize.MEDIUM;
  }

  _getContent() {
    return new FooterPopContactComponent();
  }
}
