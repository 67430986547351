import AbstractComponent from '../abstract-component.js';

const createFooterLogoTemplate = () => {
  return (
    `<div class="footer__logo footer__logo--technology">
      <p>Создано по технологии PrimeTime.today</p>
      <img src="sienge/img/logo_primetime_60x30_short.svg" alt="Логотип PrimeTime.today">
    </div>`
  );
};

export default class FooterLogoComponent extends AbstractComponent {
  getTemplate() {
    return createFooterLogoTemplate();
  }
}
