import {NTR} from "../../name-to-request";

export default class ErrorRequest {
  static setInfo({nameError, message, stack, request, response, contestId, errorType = `js`}) {
    return {
      [NTR.SET_ERROR_INFO]: {
        [NTR.ERROR_INFO]: {
          [nameError]: message,
          [NTR.STACK]: stack,
          [NTR.REQUEST]: request,
          [NTR.RESPONSE]: response,
          [NTR.CONTEST_ID]: contestId,
        },
        [NTR.ERROR_TYPE]: errorType,
      }
    };
  }
}
// Параметр error_type - тип ошибки. Принимает значения:

// "connection" - ошибки связанные с потерей интернет-соединения;
// "js" - все остальные ошибки javascript.