import {checkAccess, isEmptyObject} from '../../utils/common';
import ContestParsing from './parsing';


export default class ContestModel {
  constructor(contestId, data) {
    this.contestId = contestId;

    this.address = ContestParsing.address(data);
    this.city = ``;
    this.title = ContestParsing.titleLong(data);
    this.shortTitle = ContestParsing.titleShort(data);
    this.date = ContestParsing.date(data);
    this.timeZone = ContestParsing.timeZone(data);
    this.time_zone = data.time_zone || ``;
    this.rulesLink = ContestParsing.rulesLink(data);
    this.tariff = ContestParsing.tariff(data);
    this.tariffPrice = ContestParsing.contestServicePriceTariff(data);
    this.demo = ContestParsing.demo(data);
    this.pricePerPerformance = ContestParsing.contestServicePrice(data);
    this.currency = ContestParsing.currency(data);
    this.paymentSystem = ContestParsing.paymentSystem(data);
    this.affirmationDeadline = ContestParsing.deadlineAffimation(data);
    this.paymentDeadline = ContestParsing.deadlinePayment(data);
    this.nominations = ContestParsing.nominationTitle(data);
    this.criterias = ContestParsing.contestCriterias(data);
    this.ageCategories = ContestParsing.ageCategoryTitle(data);
    this.qualifications = ContestParsing.qualificationTitle(data);
    this.smtp = ContestParsing.smtp(data); // удалить
    this.organizationServerMail = ContestParsing.organizationServerMail(data);
    this.contestServerMail = ContestParsing.contestServerMail(data);
    this.referees = ContestParsing.referee(data);
    this.soundmans = ContestParsing.soundman(data);
    this.comperes = ContestParsing.compere(data);
    this.prizes = ContestParsing.prizeOption(data);
    this.cities = ContestParsing.contestPerformanceCity(data);
    this.documents = ContestParsing.contestDocument(data);
    this.mailTemplates = ContestParsing.mailTemplates(data);
    this.terminals = ContestParsing.terminal(data);
    this.landmarks = ContestParsing.landmarks(data);
    this.timeAvail = ContestParsing.performanceTimeAvail(data);
    this.durationSum = ContestParsing.performanceDurationSum(data);
    this.roles = ContestParsing.role(data);
    this.pauseAfterPerformance = ContestParsing.performancePause(data);
    this.players = ContestParsing.player(data);
    this.directors = ContestParsing.director(data);

    this.access = ContestModel.getAccess(data);
  }

  toRAW() {
  }

  static newContest(contestId, data) {
    return new ContestModel(contestId, data);
  }

  static parseContest(data) {
    if (isEmptyObject(data)) {
      return null;
    }

    const contestId = Object.keys(data)[0];
    return ContestModel.newContest(contestId, data[contestId]);
  }

  static parseContests(data) {
    const contestIds = Object.keys(data);
    return contestIds.map((contestId) => ContestModel.newContest(contestId, data[contestId]));
  }

  static getAccess(data) {
    return {
      address: checkAccess(data.address),
      title: checkAccess(data.contest_title),
      shortTitle: checkAccess(data.contest_title),
      date: checkAccess(data.start_date),
      timeZone: checkAccess(data.time_zone),
      rulesLink: checkAccess(data.rules_link),
      tariff: checkAccess(data.tariff),
      demo: checkAccess(data.demo),
      pricePerPerformance: checkAccess(data.contest_service_price),
      paymentSystem: checkAccess(data.payment_system),
      affirmationDeadline: checkAccess(data.deadline_affirmation),
      paymentDeadline: checkAccess(data.deadline_payment),
      nominations: checkAccess(data.nomination_title),
      criterias: checkAccess(data.criteria_title),
      ageCategories: checkAccess(data.age_category_title),
      qualifications: checkAccess(data.qualification_title),
      smtp: checkAccess(data.smtp),
      soundmans: checkAccess(data.soundman),
      comperes: checkAccess(data.compere),
      prizes: checkAccess(data.prize_option),
      cities: checkAccess(data.performance_city),
      documents: checkAccess(data.contest_document),
      mailTemplates: checkAccess(data.mail_template),
      terminals: checkAccess(data.terminal),
      timeAvail: checkAccess(data.performance_time_avail),
      durationSum: checkAccess(data.performance_duration_sum),
      pauseAfterPerformance: checkAccess(data.performance_pause),
    };
  }

  static clone(data) {
    return new ContestModel(data.toRAW());
  }
}
