import {ResponseStatusError} from "../../error/status-response";

const StatusTextCode = {
  [`500`]: `500 Internal Server Error («внутренняя ошибка сервера»)`,
  [`501`]: `501 Not Implemented («не реализовано»)`,
  [`502`]: `502 Bad Gateway («плохой, ошибочный шлюз»)`,
  [`503`]: `503 Service Unavailable («сервис недоступен»)`,
  [`504`]: `504 Gateway Timeout («шлюз не отвечает»)`,
  [`505`]: `505 HTTP Version Not Supported («версия HTTP не поддерживается»)`,
  [`506`]: `506 Variant Also Negotiates («вариант тоже проводит согласование»)`,
  [`507`]: `507 Insufficient Storage («переполнение хранилища»)`,
  [`508`]: `508 Loop Detected («обнаружено бесконечное перенаправление»)`,
  [`509`]: `509 Bandwidth Limit Exceeded («исчерпана пропускная ширина канала»)`,
  [`510`]: `510 Not Extended («не расширено»)`,
  [`511`]: `511 Network Authentication Required («требуется сетевая аутентификация»)`,
  [`520`]: `520 Unknown Error («неизвестная ошибка»)`,
  [`521`]: `521 Web Server Is Down («веб-сервер не работает»)`,
  [`522`]: `522 Connection Timed Out («соединение не отвечает»)`,
  [`523`]: `523 Origin Is Unreachable («источник недоступен»)`,
  [`524`]: `524 A Timeout Occurred («время ожидания истекло»)`,
  [`525`]: `525 SSL Handshake Failed («квитирование SSL не удалось»)`,
  [`526`]: `526 Invalid SSL Certificate («недействительный сертификат SSL»)`,
};


export default class CheckResponseStatus {
  static check(status) {
    if (status !== 200) {
      const error = new ResponseStatusError({
        message: StatusTextCode[status] || status,
      });
      throw error;
    }
  }
}

