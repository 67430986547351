import AbstractComponent from '../abstract-component.js';

const createParagraphTemplate = (className, text, title) => {
  return (
    `<p class="${className}" ${title !== `` ? `title="${title}"` : ``}>${text}</p>`
  );
};

export default class ParagraphComponent extends AbstractComponent {
  constructor({className = ``, text = ``, title = ``}) {
    super();

    this._className = className;
    this._text = text;
    this._title = title;
  }

  getTemplate() {
    return createParagraphTemplate(this._className, this._text, this._title);
  }

  setInputHandler(handler) {
    this.getElement().addEventListener(`input`, () => handler(this.text));
  }

  get text() {
    return this.getElement().innerHTML;
  }

  set text(data) {
    this._text = data;
    this.getElement().innerHTML = data;
  }
}
