export const SortType = {
  ID: `performance_id`,
  CID: `performance_cid`,
  TURN_ID: `performance_turn_id`,
  PERFORMANCE_TITLE: `performance_title`,
  RATING: `performance_rating_sum`,
  PRIZE: `performance_prize`,
  PRIZE_MANUAL: `performance_prize_manual`,
  TEAM_TITLE: `performance_team_title`,
  NOMINATION: `nomination_title`,
  AGE_CATEGORY: `nomination_age_category`,
  PHONOGRAM_STATUS: `performance_phonogram_status`,
  PLAYER_COUNT: `performance_player_count`,
};


export const sortTypeToTitle = {
  [SortType.ID]: `По регистрационному номеру в системе`,
  [SortType.CID]: `По регистрационному номеру`,
  [SortType.TURN_ID]: `По порядковому номеру выступления`,
  [SortType.PERFORMANCE_TITLE]: `По названию номера`,
  [SortType.RATING]: `По сумме баллов`,
  [SortType.PRIZE]: `По занятому месту`,
  [SortType.PRIZE_MANUAL]: `По назначенному вручную месту`,
  [SortType.TEAM_TITLE]: `По названию коллектива`,
  [SortType.NOMINATION]: `По номинации`,
  [SortType.AGE_CATEGORY]: `По возрастной категории`,
  [SortType.PHONOGRAM_STATUS]: `По статусу фонограммы`,
  [SortType.PLAYER_COUNT]: `По числу участников`,
};


export const ORDER_SORT_TYPE = [
  SortType.CID,
  SortType.TURN_ID,
  SortType.NOMINATION,
  SortType.AGE_CATEGORY,
  SortType.PLAYER_COUNT,
  SortType.PERFORMANCE_TITLE,
  SortType.TEAM_TITLE,
  SortType.PHONOGRAM_STATUS,
  SortType.RATING,
  SortType.PRIZE,
  SortType.PRIZE_MANUAL,
];

export const TURN_PAGE_ORDER_SORT_TYPE = [
  SortType.CID,
  SortType.AGE_CATEGORY,
  SortType.NOMINATION,
  SortType.PLAYER_COUNT,
  SortType.PERFORMANCE_TITLE,
  SortType.TEAM_TITLE,
  SortType.RATING,
  SortType.PRIZE,
  SortType.PRIZE_MANUAL,
  SortType.PHONOGRAM_STATUS,
];

export const SortMode = {
  BASIC: `basic`,
  EXTEND: `extend`,
};
