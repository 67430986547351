import AbstractSmartComponent from '../../abstract-smart-component.js';

const createErrorMessageTemplate = (text) => {
  return (
    `<span class="dropDownList__errorMessage">${text}</span>`
  );
};

export default class ErrorMessageComponent extends AbstractSmartComponent {
  constructor(text) {
    super();

    this._text = text;
  }

  set text(value) {
    this._text = value;

    super.rerender();
  }

  recoveryListeners() {}

  getTemplate() {
    return createErrorMessageTemplate(this._text);
  }
}
