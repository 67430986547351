import FooterPopIframeComponent from "../component/footer-pop-iframe/footer-pop-iframe";
import {ServiceEnToServiceRus} from "../const";
import Popup, {ContentType} from "../static/popup/popup";

export default class ServiceController {
  render() {
    const content = this._getContent();

    Popup.showCustom({
      size: this._popupSize,
      title: ServiceEnToServiceRus[this._service],
      contents: [
        {
          type: ContentType.FRAGMENT,
          content: content.getElement(),
        },
      ]
    });
  }

  _getContent() {
    return new FooterPopIframeComponent(this._link);
  }
}
