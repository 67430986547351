export const UseMailTemplate = {
  PLAYER_REGISTRATION: `playerRegistration`,
  PLAYER_PAY: `playerPay`,
  ORGANIZATION_REGISTRATION: `organizationRegistration`,
};

export const UseMailTemplateToRus = {
  [UseMailTemplate.PLAYER_REGISTRATION]: `Участнику при регистрации`,
  [UseMailTemplate.PLAYER_PAY]: `Участнику из раздела «Оплата»`,
  [UseMailTemplate.ORGANIZATION_REGISTRATION]: `Организатору о новой заявке`,
};

export const MailTemplate = {
  REGISTRATION: `registration`,
  REGISTRATION_GROUP: `registration-group`,
  PAY: `pay`,
  PAY_GROUP: `pay-group`,
  OWNER: `owner`,
  OWNER_GROUP: `owner-group`,
  PASSWORD: `password`,
  WARNING_REGISTRATION_FORM: `warning-registration-form`,
};

export const MailTemplateId = {
  [MailTemplate.REGISTRATION]: `214`,
  [MailTemplate.REGISTRATION_GROUP]: `213`,
  [MailTemplate.PAY]: `210`,
  [MailTemplate.PAY_GROUP]: `209`,
  [MailTemplate.OWNER]: `207`,
  [MailTemplate.OWNER_GROUP]: `206`,
  [MailTemplate.PASSWORD]: `208`,
  [MailTemplate.WARNING_REGISTRATION_FORM]: `217`,
};

export const DefaultMailTemplate = {
  [UseMailTemplate.PLAYER_REGISTRATION]: MailTemplateId[MailTemplate.REGISTRATION],
  [UseMailTemplate.PLAYER_PAY]: MailTemplateId[MailTemplate.PAY],
  [UseMailTemplate.ORGANIZATION_REGISTRATION]: MailTemplateId[MailTemplate.OWNER],
};
