export const AlertType = {
  CURRENT_PERFORMANCE: `current_performance`,
  PRIZE: `prize`,
  PERFORMANCE_RATING: `performance_rating`,
  MONEY: `money`,
  PERFORMANCE_PLAY: `performance_play`,
  PERFORMANCE_PAUSE: `performance_pause`,
  PERFORMANCE_STOP: `performance_stop`,
  DOCUMENT: `document`,
  PHONOGRAM_UPDATE: `phonogram_update`,
  PHONOGRAM_STOP: `phonogram_stop`,
  PERFORMANCE_READY: `performance_ready`,
  DEBUG: `debug_mode`,
  PERFORMANCE_CHANGE: `performance_change`,
  ERROR: `error`,
};

export const AlertTypeToRus = {
  [AlertType.CURRENT_PERFORMANCE]: `Объявить`,
  [AlertType.PERFORMANCE_PLAY]: `Запустить`,
  [AlertType.PERFORMANCE_PAUSE]: `Пауза`,
  [AlertType.PERFORMANCE_STOP]: `Финиш`,
  [AlertType.PRIZE]: `Назначен приз`,
};
