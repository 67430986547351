import Popup from "../static/popup/popup";

export class RequestError extends Error {
  constructor({message = ``, id = ``, textError = ``, parameter = {}}) {
    super(message);

    this.name = `RequestError`;
    this.id = id;
    this.textError = textError;
    this.parameter = parameter;
  }

  show() {
    const textError = !this.textError ? `Ошибка загрузки или отправки данных.` : this.textError;

    Popup.showError({
      text:
        `${textError} <br>
        ${this.id ? `Код ошибки: ${this.id}.` : ``} `,
    });
  }

  showCustom(textError, isShowSupport = true) {
    Popup.showError({
      text:
        `${textError} <br>
        ${this.id ? `Код ошибки: ${this.id}.` : ``} `,
      isShowSupport,
    });
  }
}
